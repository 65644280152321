import moment from 'moment-timezone';

export const formShape = {
    phone: '',
    businessPhone: '',
    email: '',
    nameFirst: '',
    nameLast: '',
    schemeID: '',
    password: '',
    repeatPassword: '',
    charityPreferred: undefined,
    unitNumber: '',
    POBox: '',
    location: {
        description: '',
        lat: '',
        lng: '',
        place_id: ''
    },
    securityQuestion: '',
    securityAnswer: '',
    // existing account:
    existingEmail: '',
    // charity:
    charityName: '',
    registeredCharityName: '',
    charityRegistrationNumber: '',
    charitySchemeID: '',
    redemptionEmail: '',
    website: '',
    mission: '',
    subdivisions: [],
    hasCloseDate: false,
    closeDate: moment().add(1, 'year'),
    //bottleDrive
    bottleDriveName: '',
    bottleDriveCharity: null,
    locationType: '',
    //business
    businessName: '',
    registeredBusinessName: '',
    businessNumber: ''
};

export const errors = {
    phone: ['phone'],
    businessPhone: ['phone'],
    email: ['email'],
    nameFirst: ['required', 'name'],
    nameLast: ['required', 'name'],
    password: ['required', 'password'],
    repeatPassword: ['required', 'password'],
    securityQuestion: ['required'],
    securityAnswer: ['required'],
    // commercial
    businessName: ['max-length:50'],
    registeredBusinessName: ['required', 'max-length:100'],
    // existing account:
    existingEmail: ['email'],
    // charity:
    charityName: ['required', 'max-length:50'],
    charityRegistrationNumber: ['required', process.env.REACT_APP_CHARITY_NUMBER_VALIDATION_RULE],
    registeredCharityName: ['max-length:100'],
    redemptionEmail: ['email'],
    website: ['url'],
    mission: ['required', 'max-length:300'],
    location: {
        description: ['required'],
        lat: ['required', 'latCoordinate'],
        lng: ['required', 'lngCoordinate'],
        place_id: ['required']
    },
    //bottleDrive
    bottleDriveName: ['required'],
    bottleDriveCharity: ['notNull'],
    locationType: ['required'],
    unitNumber: ['max-length:100']
};
