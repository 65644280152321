import React, { useContext, useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';

import _ from 'lodash';
import * as qs from 'query-string';
import * as timeHelper from 'helpers/timeHelper';
import { _time } from 'std';

import * as terms from 'localizations/terms';
import {
    getDeviceInformation,
    deviceHelper,
    formatsSecondsToTime,
    getWindowWidth,
    getWindowHeight,
    isAUSRegion,
    isEXPRegion,
    isCONRegion
} from 'utils/misc.js';
import { validate, initFormErrors, containsErrors } from 'utils/validate';
import { maxFileSize, maxFileSizeMB, maxCharityImageWidthAndHeight } from 'constants.js';

import { createLocation, getEmptyLocation } from 'helpers/locationHelper';

import { Helmet } from 'react-helmet';

import ContainerWithLogo from 'components/ContainerWithLogo/ContainerWithLogo';
import CharityInfo from 'components/CharityInfo/CharityInfo';
import HowItWorks from 'components/InfoDialogs/HowItWorks';
import ContactForm from 'components/ContactForm/ContactForm';
import OAuthDialog from 'components/OAuthDialog/OAuthDialog';
import useOAuthDialog from 'components/OAuthDialog/hooks/useOAuthDialog';
import FAQ from 'components/InfoDialogs/FAQ';
import ExternalLink from 'components/ExternalLink/ExternalLink';
// import AppStoreLink from 'components/AppStoreLink/AppStoreLink';
import NewUserInfo from 'components/TextComponents/NewUserInfo';
import FeaturedCharitySelectDialog from 'components/Dialogs/Charity/FeaturedCharitySelectDialog';

import FormSelector from './screens/FormSelector';
import AccountInformationForm from './screens/AccountInformationForm';
import CharityInformationForm from './screens/CharityInformationForm';
import BottleDriveInformationForm from './screens/BottleDriveInformationForm';

import TextMaskPhoneNumber from 'components/TextMasks/TextMaskPhoneNumber';

import NoSMSDialog from './NoSMSDialog';

import imageCompression from 'browser-image-compression';

import {
    Collapse,
    CircularProgress,
    Paper,
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Icon,
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    InputLabel,
    Select,
    MenuItem,
    withWidth,
    colors,
    ListItem,
    ListItemAvatar,
    ListItemText
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { withTheme } from '@material-ui/core/styles';
/*import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';*/

import { getClosestRegion } from 'utils/latlngFunctions';
import { _charity } from 'std';

import { App } from '@capacitor/app';
import { NativeBiometric } from '@capgo/capacitor-native-biometric';

import { formShape, errors } from './constants/form';
import {
    keysUser,
    keysCharity,
    keysExistingUser,
    keysValidated,
    trimmedKeys,
    keysBottleDrive,
    keysCommercial
} from './constants/keys';
import { pageTitle, descriptions } from './constants/text';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import HttpContext from 'utils/contexts/HttpContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import useIsMounted from 'utils/hooks/useIsMounted';

import { loc } from 'localizations/localizationHandler';

import { useRef } from 'react';

import { AVAILABLE_LANGS, REGISTRATION_TABS } from '../../constants';
import useDefaultCoordinates from 'utils/hooks/useDefaultCoordinates';

import useQuestionnaireForm from 'components/QuestionnaireForm/hooks/useQuestionnaireForm';
import PromoCodesInput from 'components/InputComponents/PromoCodesInput';
import FakeLink from 'components/FakeLink/FakeLink';
import RoundSquareButton from 'components/Buttons/RoundSquareButton';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Card from 'containers/Operators/Dashboard/Card';

const Analytics = require('utils/analytics.js');
const logo = require(process.env.REACT_APP_LOGO);
const splashScreenBackgroundOverlay = require('icons/Bottle-Pattern-White-Tiles-2.svg');
const splashScreenBackgroundOverlayNoMilk = require('icons/Bottle-Pattern-No-Jug.svg');
const splashScreenBackgroundOverlayEXP_1 = require('icons/Beverage_Icons_Express.png');
const splashScreenBackgroundOverlayEXP_2 = require('icons/Beverage_Icons_Express_2.png');

const backgroundImage = isSmallScreen => {
    if (isEXPRegion() && isSmallScreen) {
        return splashScreenBackgroundOverlayEXP_1;
    }

    if (isEXPRegion() && !isSmallScreen) {
        return splashScreenBackgroundOverlayEXP_2;
    }

    if (isCONRegion()) {
        // return splashScreenBackgroundOverlayNoMilk;
        return null;
    }

    return splashScreenBackgroundOverlay;
};

function Registration(props) {
    const {
        history,
        location: reactRouterLocation,
        dispatch,
        theme,
        useFallbackData,
        showThirdPartyLogins,
        promosEnabled,
        helpCenterFunctions,
        auth
    } = props;

    const { lang, setLang } = useContext(LocalizationContext);
    const availableLangs = AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT];
    const onSnackbar = useContext(SnackbarContext);
    const http = useContext(HttpContext);

    const isMounted = useIsMounted();
    const { defaultCoordinates, loading: defaultCoordinatesLoading } = useDefaultCoordinates(http);

    const [tabIndex, setTabIndex] = useState();
    const [activeStep, setActiveStep] = useState(0);
    const [helpCenterOpen, setHelpCenterOpen] = useState(false);
    const [howItWorksOpen, setHowItWorksOpen] = useState(false);
    const [faqOpen, setFaqOpen] = useState(false);
    const [charity, setCharity] = useState(null);
    const [subdivision, setSubdivision] = useState(null);
    const [charities, setCharities] = useState([]);
    const [promptedHasAnAccount, setPromptedHasAnAccount] = useState(false);
    const [hasAnAccount, setHasAnAccount] = useState(false);
    const [taxReceiptsIssued, setTaxReceiptsIssued] = useState(false);
    const [differentTeams, setDifferentTeams] = useState(false);
    const [ipAddressLocation, setIPAddressLocation] = useState(createLocation({}, '', 0, 0));
    const [form, setForm] = useState(_.cloneDeep(formShape));
    const [formErrors, setFormErrors] = useState(initFormErrors(formShape));
    const [file, setFile] = useState();
    const [inProgress, setInProgress] = useState(false);
    const [servicesLoading, setServicesLoading] = useState(false);
    const [contactFormOpen, setContactFormOpen] = useState(false);
    const [promoInputShown, setPromoInputShown] = useState(false);
    const [promos, setPromos] = useState([]);
    const [askForCharityDialog, setAskForCharityDialog] = useState(false);
    const [promptedForCharity, setPromptedForCharity] = useState(false);
    const [redirectedFromApp, setRedirectedFromApp] = useState(false);
    const [regions, setRegions] = useState([]);
    const [adgroup, setAdGroup] = useState();
    const [ad_id, setAdd_id] = useState();
    const [bottleDriveDonate, setBottleDriveDonate] = useState(false);
    const [suggestedCoordinates, setSuggestedCoordinates] = useState(null);
    const [loginOnSuccess, setLoginOnSuccess] = useState(false);
    const [questionnaire, setQuestionnaire] = useState(null);
    // const [registrationTagline, setRegistrationTagline] = useState(_.get(registrationConfig, `tagline.${lang}`, null));
    // const [registrationOptions, setRegistrationOptions] = useState(_.get(registrationConfig, `options`, {}));
    const [services, setServices] = useState([]);

    const [splashScreen, setSplashScreen] = useState(false);
    const [moreInformationExpanded, setMoreInformationExpanded] = useState(false);

    //two-factor Authentication
    const [twoFactorAuthenticationCode, setTwoFactorAuthenticationCode] = useState('');
    const [require2FABeforeRegistration, setRequire2FABeforeRegistration] = useState(false);
    const [preRegistration2FAComplete, setPreRegistration2FAComplete] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState(false);

    const [twoFactorAuthenticationPhoneNumber, setTwoFactorAuthenticationPhoneNumber] = useState('');
    const [twoFactorAuthenticationStep, setTwoFactorAuthenticationStep] = useState(false);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [twoFactorAuthenticationCodeInputDisabled, setTwoFactorAuthenticationCodeInputDisabled] = useState(true);
    // const [channel, setChannel] = useState('sms');
    const [phoneNumberInputRequired, setPhoneNumberInputRequired] = useState(false);
    const [enableSendTime, setEnableSendTime] = useState(null);
    const [trustThisDevice, setTrustThisDevice] = useState(false);
    const [authenticationSID, setAuthenticationSID] = useState('');
    const [accountLocked, setAccountLocked] = useState(false);
    const [lockAccountUntil, setLockAccountUntil] = useState(null);
    const [oAuthUID, setOAuthUID] = useState(null);
    const codeInputRef = useRef(null);
    const preCodeInputRef = useRef(null);
    const [noSMSDialogOpen, setNoSMSDialogOpen] = useState(false);

    const [windowWidth, setWindowWidth] = useState(getWindowWidth);
    const [windowHeight, setWindowHeight] = useState(getWindowHeight);

    const [accountLinkSuccessful, setAccountLinkSuccessful] = useState(false);

    const [isInAppBrowser, setIsInAppBrowser] = useState(false);
    const [biometricsAvailable, setBiometricsAvailable] = useState(false);
    const [biometricsOptIn, setBiometricsOptIn] = useState(false);

    const [registrationConfig, setRegistrationConfig] = useState({});

    const {
        responses: questionnaireResponses,
        formIncomplete: questionnaireFormIncomplete,
        touchedInputs,
        overrideTouched,
        handleResponseSelected,
        handleCustomReponseChanged,
        handleOverrideTouched
    } = useQuestionnaireForm({
        questionnaire
    });

    const validateForm = (keys, updatedForm = form) => {
        if (_.isEmpty(keys)) {
            keys = getValidatedKeys(
                tabIndex,
                hasAnAccount,
                taxReceiptsIssued,
                oAuthRegistration,
                form.locationType === 'Commercial',
                registrationConfig
            );
        }

        let isGroupAcct = tabIndex === REGISTRATION_TABS.GROUP;
        let formErrorsUpdated = _.cloneDeep(formErrors);
        for (let key of keys) {
            if (isEXPRegion() && isGroupAcct && ['website', 'mission'].includes(key) && _.isEmpty(updatedForm[key])) {
                // Not required on Express GROUP accounts
                continue;
            }
            if (_.isPlainObject(updatedForm[key])) {
                for (let inner_key in updatedForm[key]) {
                    formErrorsUpdated[key][inner_key] = validate(
                        errors[key][inner_key],
                        updatedForm[key][inner_key],
                        lang
                    );
                }
            } else {
                formErrorsUpdated[key] = validate(errors[key], updatedForm[key], lang);
            }
        }

        setFormErrors(formErrorsUpdated);
        return formErrorsUpdated;
    };

    const {
        showAuthDialog,
        oAuthRegistration,
        oAuthUser,
        handleOAuth,
        handleCustomAuth, // Handles auth with unsupported 3rd party provider
        handleRegisterOAuthCustomer,
        handleRegisterOAuthCharityAndBottleDrive,
        handleCancelOAuthRegistration,
        setShowAuthDialog
    } = useOAuthDialog({
        dispatch,
        history,
        charity,
        ad_id,
        adgroup,
        tabIndex,
        form,
        file,
        promos,
        ipAddressLocation,
        differentTeams,
        hasAnAccount,
        taxReceiptsIssued,
        setForm,
        validateForm,
        getValidatedKeys,
        setTabIndex,
        setInProgress
    });

    const featuredCharities = useMemo(() => _.filter(charities, charity => _charity.isFeatured(charity)), [charities]);
    const showAlternativeLogins =
        showThirdPartyLogins &&
        (tabIndex === REGISTRATION_TABS.PERSONAL ||
            tabIndex === REGISTRATION_TABS.BUSINESS ||
            [REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex) ||
            tabIndex === REGISTRATION_TABS.BOTTLE_DRIVE);

    const handleCancelTwoFactorAuthentication = async () => {
        history.push('/login');
    };

    const focusOnCodeInput = () => {
        if (require2FABeforeRegistration) {
            if (preCodeInputRef.current && !twoFactorAuthenticationCodeInputDisabled) {
                preCodeInputRef.current.focus();
            } else if (preCodeInputRef.current && twoFactorAuthenticationCodeInputDisabled) {
                setTimeout(() => {
                    preCodeInputRef.current.focus();
                }, 50);
            }
        } else {
            if (codeInputRef.current && !twoFactorAuthenticationCodeInputDisabled) {
                codeInputRef.current.focus();
            } else if (codeInputRef.current && twoFactorAuthenticationCodeInputDisabled) {
                setTimeout(() => {
                    codeInputRef.current.focus();
                }, 50);
            }
        }
    };

    const queryUrl = async mounted => {
        let query = qs.parse(reactRouterLocation.search);
        if (mounted === false) {
            // component not mounted, no need for state change
            return;
        }
        const queryKeys = Object.keys(query);
        const promoQueryKey = _.find(queryKeys, key => key.toUpperCase() === 'PROMOCODE'); //make query key for promo code case insensitive
        let tabIndexFromQuery;
        if (!_.isNil(query.tab)) {
            tabIndexFromQuery = parseTabIntoIndex(query.tab);
            setTabIndex(tabIndexFromQuery);
            if (tabIndexFromQuery === REGISTRATION_TABS.CHARITY) {
                setTaxReceiptsIssued(isEXPRegion());
            }
        }

        if (!_.isNil(query.existingEmail)) {
            handleSetHasAnAccount(true);
            setPromptedHasAnAccount(true);
            let updatedForm = { ...form, existingEmail: query.existingEmail };
            setForm(updatedForm);
            validateForm(['existingEmail'], { ...form, existingEmail: query.existingEmail });
            if (parseTabIntoIndex(query.tab) === REGISTRATION_TABS.GROUP) {
                setTaxReceiptsIssued(false);
                setTabIndex(REGISTRATION_TABS.GROUP);
            } else {
                setTaxReceiptsIssued(isEXPRegion());
                setTabIndex(REGISTRATION_TABS.CHARITY);
            }
            setActiveStep(2);
            setRedirectedFromApp(1);
        }

        if (!_.isEmpty(query[promoQueryKey])) {
            let res = await http.postJSON(`/promos/validate/${JSON.stringify(query[promoQueryKey])}`, {});
            if (res.ok) {
                if (res.data.result.valid) {
                    setPromoInputShown(true);
                    setPromos([res.data.promo]);
                } else {
                    onSnackbar(loc('refererNotQualified', lang), 'error');
                }
            } else {
                onSnackbar(loc('unableToApplyPromo', lang), 'error');
            }
        }

        if (!_.isEmpty(query.org) || !_.isEmpty(query.charity_id)) {
            if (!_.isEmpty(query.charity_id)) {
                query.org = query.charity_id;
            }

            let res = await http.getJSON('/getCharityByUrl/' + query.org.toLowerCase(), false, true);
            if (res.ok) {
                if (!_.isNil(res.data.charity) && res.data.charity.approved) {
                    if (res.data.charity.closed) {
                        onSnackbar(loc('orgLinkExpired', lang), 'error');
                    } else {
                        const subdivision = _charity.findSubdivision(
                            _.get(res, 'data.charity'),
                            _.get(query, 'subdivision')
                        );
                        setTabIndex(REGISTRATION_TABS.PERSONAL);
                        setCharity(res.data.charity);
                        setSubdivision(subdivision);
                        const updatedForm = { ...form, charityPreferred: query.org };
                        setForm(updatedForm);
                        validateForm(['charityPreferred'], updatedForm);
                        /*let tabParam = 'tab=customer';
                    let orgParam = `&org=${query.org}`;
                    let subdivisionParam = !_.isNil(subdivision) ? `&subdivision=${_.get(query, 'subdivision')}` : '';
                    history.push('/register?' + tabParam + orgParam + subdivisionParam);*/
                    }
                } else {
                    onSnackbar(loc('orgLinkInvalid', lang), 'error');
                }
            }
        }

        if (!_.isEmpty(query.howitworks)) {
            setTabIndex(parseTabIntoIndex(query.howitworks));
            setHowItWorksOpen(true);
        }

        if (!_.isEmpty(query.faq)) {
            setTabIndex(parseTabIntoIndex(query.howitworks));
            setFaqOpen(true);
        }

        if (!_.isEmpty(query.adgroup)) {
            setAdGroup(query.adgroup);
        }

        if (!_.isEmpty(query.ad_id)) {
            setAdd_id(query.ad_id);
        }
    };

    const handleBackToSelection = () => {
        setTabIndex(undefined);
    };

    const handleResize = e => {
        const setWindowDimensions = () => {
            setWindowWidth(getWindowWidth());
            setWindowHeight(getWindowHeight());
        };
        setTimeout(function() {
            setWindowDimensions();
        }, 250);
    };

    const handleTabIndexChange = index => async () => {
        if (isEXPRegion()) {
            if (index === REGISTRATION_TABS.CHARITY) {
                setTaxReceiptsIssued(true);
            } else {
                setTaxReceiptsIssued(false);
            }
        }
        setTabIndex(index);
        setActiveStep(0);
        setHasAnAccount(false);
        setPromptedHasAnAccount(false);
        setFormErrors(initFormErrors(formShape));
    };

    const handleNext = () => {
        let keys = hasAnAccount ? keysExistingUser : keysUser;
        if (!_.get(registrationConfig, 'showPOBoxInput', false)) {
            keys = _.without(keys, 'POBox');
        }
        if (!_.get(registrationConfig, 'showRepeatPassword', false)) {
            keys = _.without(keys, 'repeatPassword');
        }
        if (!_.get(registrationConfig, 'showSecurityQuestions', false)) {
            keys = _.without(keys, 'securityQuestion');
            keys = _.without(keys, 'securityAnswer');
        }
        let errorsLatest = validateForm(keys);
        handleOverrideTouched(true);
        //go to next step if registering an organisation using oAuth don't need to validate form for this step
        if (!(containsErrors(errorsLatest) || (questionnaireFormIncomplete && !hasAnAccount)) || oAuthRegistration) {
            setActiveStep(activeStep + 1);
            setFormErrors(initFormErrors(formShape));
            setShowAuthDialog(false);
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
        handleCancelOAuthRegistration();
        handleOverrideTouched(false);
        setFormErrors(initFormErrors(formShape));
    };

    const handleFAQ = state => () => {
        setFaqOpen(state);
    };

    const handleHowItWorks = state => () => {
        setHowItWorksOpen(state);
    };

    const handleTrustThisDevice = e => () => {
        setTrustThisDevice(e.target.checked);
    };

    const handleHelpCenter = state => () => {
        setHelpCenterOpen(state);
    };

    const handleFormCloseDateChange = date => {
        const updatedForm = { ...form, closeDate: date };
        setForm(updatedForm);
    };

    const handleFormChange = e => {
        let name = e.target.name;
        let value = e.target.value;

        // if (trimmedKeys.includes(name)) {
        //     value = value.trim();
        // }
        // trim the () and the - from phone
        if (name === 'phone') {
            if (value !== form.phone) {
                setPreRegistration2FAComplete(false);
            }
            value = e.target.value.replace(/\D+/g, '');
        }
        if (name === 'hasCloseDate') {
            value = e.target.checked;
        }
        const updatedForm = { ...form, [name]: value };
        setForm(updatedForm);
        validateForm([name], updatedForm);
    };

    const handleChangeLocationType = locationType => {
        const updatedForm = { ...form, locationType };
        setForm(updatedForm);
        validateForm('locationType', updatedForm);
    };

    // const handleTwoFactorAuthenticationChannelChange = e => {
    //     setChannel(e.target.value.toString());
    // };

    const handleTwoFactorAuthenticationCodeChange = e => {
        setTwoFactorAuthenticationCode(e.target.value);
    };

    const handleTwoFactorAuthenticationPhoneNumberChange = e => {
        handleSendVerificationCode({
            twoFactorAuthenticationPhoneNumber: phoneNumber,
            channel: 'sms',
            authenticationSID,
            oAuthUID,
            email: form.email.toLowerCase().trim()
        });
        setPhoneNumber('');
        setPhoneNumberInputRequired(false);
    };

    const handleUpdatePhoneNumber = e => {
        const inputPhoneNumber = e.target.value.replace(/\D/g, '');
        setPhoneNumber(inputPhoneNumber);
    };

    const handleSubdivisionsChange = subdivisions => {
        const updatedForm = { ...form, subdivisions };
        setForm(updatedForm);
        validateForm(['subdivision'], updatedForm);
    };

    const onRegisterOAuthCustomer = async oAuthUser => {
        let res = await handleRegisterOAuthCustomer(oAuthUser);
        if (_.get(res, 'twoFactorAuthenticationCodeRequired', false)) {
            setTwoFactorAuthenticationStep(true);
            setTwoFactorAuthenticationPhoneNumber(_.get(res, 'twoFactorAuthenticationPhoneNumber', ''));
            setTwoFactorAuthenticationCode('');
            setPhoneNumberInputRequired(_.isEmpty(res.phoneNumber));
            // setChannel(_.get(res, 'channel'));
            setLoginOnSuccess(_.get(res, 'loginOnSuccess', false));
            setOAuthUID(_.get(res, 'oAuthUID', ''));

            handleSendVerificationCode({
                twoFactorAuthenticationPhoneNumber: _.get(res, 'twoFactorAuthenticationPhoneNumber', ''),
                channel: _.get(res, 'channel'),
                authenticationSID,
                oAuthUID: _.get(res, 'oAuthUID', ''),
                email: form.email.toLowerCase().trim()
            });
        }
    };

    const handleDrop = async e => {
        const options = {
            maxSizeMB: maxFileSizeMB,
            maxWidthOrHeight: maxCharityImageWidthAndHeight,
            fileType: 'image/png',
            useWebWorker: true
        };
        const img = e.target.files[0];

        let validImageTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/heif', 'image/heic', 'image/gif'];
        let validImageExtensions = ['.jpeg', '.jpg', '.png', '.webp', '.heif', '.heic', '.gif'];
        let isValidExt = validImageExtensions.some(
            type => _.endsWith(img.name, type) || _.endsWith(img.name, _.toUpper(type))
        );

        if (_.includes(validImageTypes, _.get(img, 'type')) && isValidExt) {
            const imageFile = await imageCompression(img, options);
            setFile(imageFile);
        } else {
            onSnackbar(loc('invalidImageFormat', lang), 'error');
        }
    };

    const handleSetHasAnAccount = newHasAnAccount => {
        let keys = getValidatedKeys(tabIndex, newHasAnAccount, taxReceiptsIssued, null, false, registrationConfig);

        //pre-populate existing email and email
        let newForm = form;
        if (newHasAnAccount) {
            newForm.existingEmail = form.email;
        } else {
            newForm.email = form.existingEmail;
        }

        setHasAnAccount(newHasAnAccount);
        setPromptedHasAnAccount(true);
        setFormErrors(initFormErrors(formShape, form, formErrors, keys));
        setForm(newForm);
        setActiveStep(activeStep + 1);
    };

    const handleToggleHasAnAccount = () => {
        let keys = getValidatedKeys(tabIndex, !hasAnAccount, taxReceiptsIssued, null, false, registrationConfig); // NB: flip the flag!

        //pre-populate existing email and email
        let newForm = form;
        if (!hasAnAccount) {
            newForm.existingEmail = form.email;
        } else {
            newForm.email = form.existingEmail;
        }

        setHasAnAccount(!hasAnAccount);
        setFormErrors(initFormErrors(formShape, form, formErrors, keys));
        setForm(newForm);
    };

    const handleToggleTaxReceiptsIssued = () => {
        let keys = getValidatedKeys(tabIndex, hasAnAccount, !taxReceiptsIssued, null, false, registrationConfig); // NB: flip the flag!

        setTaxReceiptsIssued(!taxReceiptsIssued);
        setFormErrors(initFormErrors(formShape, form, formErrors, keys));
    };

    const handleToggleDifferentTeams = () => {
        setDifferentTeams(!differentTeams);
    };
    const disableSendButton = timeoutInSeconds => {
        setResendDisabled(true);
        setTimeout(() => {
            setResendDisabled(false);
        }, timeoutInSeconds * 1000);
    };

    const handleSubmit = async (charitySelectedFromDialog = null) => {
        /*let errorsLatest = validateForm();

        if (containsErrors(errorsLatest)) {
            return;
        }*/

        if ([REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex) && _.isNil(file)) {
            if (!(isEXPRegion() && tabIndex === REGISTRATION_TABS.GROUP)) {
                // Express GROUP accounts don't need Logo's, Charity's do though
                return onSnackbar(loc('uploadLogoFirst', lang), 'error');
            }
        }

        let keys = getValidatedKeys(
            tabIndex,
            hasAnAccount,
            taxReceiptsIssued,
            oAuthRegistration,
            form.locationType === 'Commercial',
            registrationConfig
        );
        let formAdjusted = _.pick(form, keys);
        if (!_.isNil(charity) && (tabIndex === REGISTRATION_TABS.PERSONAL || tabIndex === REGISTRATION_TABS.BUSINESS)) {
            formAdjusted.charityPreferred = charity._id;
            formAdjusted.subdivisionPreferred = subdivision;
        } else if (
            !_.isNil(charitySelectedFromDialog) &&
            (tabIndex === REGISTRATION_TABS.PERSONAL || tabIndex === REGISTRATION_TABS.BUSINESS)
        ) {
            formAdjusted.charityPreferred = charitySelectedFromDialog._id;
            formAdjusted.subdivisionPreferred = subdivision;
        }

        if (!_.isNil(_.get(form, 'bottleDriveCharity'))) {
            formAdjusted.charityPreferred = _.get(form, 'bottleDriveCharity');
            formAdjusted.subdivisionPreferred = _.get(form, 'bottleDriveName');
        }

        if (!differentTeams) {
            formAdjusted.subdivisions = [];
        }

        if (!form.hasCloseDate) {
            formAdjusted.closeDate = null;
        }
        if (tabIndex === REGISTRATION_TABS.GROUP) {
            formAdjusted.charityType = 'GROUP';
        } else if (tabIndex === REGISTRATION_TABS.CHARITY) {
            formAdjusted.charityType = 'CHARITY';
        }

        formAdjusted.promos = promos;
        formAdjusted.adgroup = adgroup;
        formAdjusted.ad_id = ad_id;
        setInProgress(true);

        formAdjusted.deviceInfo = await getDeviceInformation(); // get information about the device

        formAdjusted.lang = lang;

        formAdjusted.questionnaireReponses = questionnaireResponses;

        const formData = new FormData(); // NB: FormData objects cannot be displayed in console.log
        if (!_.isEmpty(formAdjusted.location)) {
            formAdjusted.location.timezone = timeHelper.getTimezone();
        } else {
            formAdjusted.location = ipAddressLocation; //use location inferred from ip address if no address entered in form
            formAdjusted.location.timezone = timeHelper.getTimezone();
        }
        if ([REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex)) {
            formData.append('logo', file || '');
            if (_.isNil(formAdjusted.registeredCharityName) || _.isEmpty(formAdjusted.registeredCharityName)) {
                formAdjusted.registeredCharityName = form.charityName;
            }
        }

        formData.append('form', JSON.stringify(formAdjusted)); // will show up in req.body

        const res = await http.postJSON('/register', formData, true, true);
        if (res.ok) {
            if (!deviceHelper.isNativeApp()) {
                // Analytics
                if (!_.isNil(window.fbq)) {
                    window.fbq('track', 'CompleteRegistration');
                }

                if (formAdjusted.charityName) {
                    Analytics.logEvent('Registration', 'Organization');
                    Analytics.logFacebookEvent('Registration Organization');
                } else {
                    Analytics.logEvent('Registration', 'Customer');
                    Analytics.logFacebookEvent('Registration Customer');
                }
            }

            if (deviceHelper.isNativeApp() && biometricsAvailable && biometricsOptIn) {
                try {
                    //Save user's credentials to the device - Keychain (iOS) and Keystore (Android).
                    await NativeBiometric.setCredentials({
                        username: formAdjusted.email,
                        password: formAdjusted.password,
                        server: process.env.REACT_APP_ORIGIN_URL
                    });
                    window.localStorage.setItem('biometricsHasCredentials', 'true');
                } catch (err) {}
            }

            if (_.get(res, 'data.twoFactorAuthenticationRequired', false)) {
                setTwoFactorAuthenticationStep(true);
                setTwoFactorAuthenticationPhoneNumber(_.get(res, 'data.twoFactorAuthenticationPhoneNumber', ''));
                setTwoFactorAuthenticationCode('');
                setPhoneNumberInputRequired(_.isEmpty(res, 'data.twoFactorAuthenticationPhoneNumber'));
                // setChannel(_.get(res, 'data.channel'));
                setLoginOnSuccess(_.get(res, 'data.loginOnSuccess', false));

                handleSendVerificationCode({
                    twoFactorAuthenticationPhoneNumber: _.get(res, 'data.twoFactorAuthenticationPhoneNumber', ''),
                    channel: _.get(res, 'data.channel'),
                    authenticationSID,
                    email: form.email.toLowerCase().trim()
                });
            } else if (_.get(res, 'data.loginOnSuccess', false)) {
                // set home url and authorized
                const user = _.get(res, 'data.user', {});
                let homeURL = _.get(res, 'data.homeURL', '/login');

                // Redirect login to charity page if registered account is a charity
                if (
                    [REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex) &&
                    homeURL !== '/login' &&
                    !_.isEmpty(user.charities)
                ) {
                    homeURL += '/' + _.first(user.charities) + '/charity';
                }

                Analytics.logFacebookEvent('Customer Login');

                dispatch({
                    type: 'SET_AUTH_TRUE',
                    accountType: user.accountType,
                    multipleAccountAccessList: user.multipleAccountAccessList,
                    name: !_.isNil(user.name) ? user.name.first + ' ' + user.name.last : undefined,
                    _id: user._id,
                    collector_id: _.get(user, 'collector._id', undefined),
                    home: homeURL
                });
                //redirect to home url
                //history.push(homeURL); //Moved into useEffect to fix issue with state not being updated before redirect
            } else {
                setAccountLinkSuccessful(true);
            }
        } else if (res.status === 400) {
            onSnackbar(_.get(res, 'errorMessage', loc('badRequest', lang)) + '.', 'error');
        } else if (res.status === 413) {
            // do nothing (handled by the default error dialog)
        } else {
            onSnackbar(loc('somethingWentWrong', lang), 'error');
        }
        if (isMounted()) {
            setInProgress(false);
        }
    };

    const handleSubmitClicked = async e => {
        let errorsLatest = validateForm();
        handleOverrideTouched(true);
        if (containsErrors(errorsLatest) || (questionnaireFormIncomplete && !hasAnAccount && !oAuthRegistration)) {
            return;
        }

        // After all checks complete, ask to select a charity preference:
        const charitiesSameCity = filterCharitiesByRegion();
        if (
            props.charityEnabled &&
            _.isNil(charity) &&
            !promptedForCharity &&
            !_.isEmpty(charitiesSameCity) &&
            [REGISTRATION_TABS.PERSONAL, REGISTRATION_TABS.BUSINESS].includes(tabIndex)
        ) {
            return setAskForCharityDialog(true);
        }
        if (!oAuthRegistration) {
            handleSubmit();
        } else {
            handleRegisterOAuthCharityAndBottleDrive();
        }
    };

    const handleSendVerificationCodeClick = async (channel = 'sms') => {
        const { http } = props;
        if (require2FABeforeRegistration) {
            handleSendPreRegistrationVerificationCode({
                twoFactorAuthenticationPhoneNumber: form.phone,
                channel,
                authenticationSID
            });
        } else {
            handleSendVerificationCode({
                twoFactorAuthenticationPhoneNumber,
                channel,
                authenticationSID,
                oAuthUID,
                email: form.email.toLowerCase().trim()
            });
        }
    };

    const handleSendPreRegistrationVerificationCode = async ({
        twoFactorAuthenticationPhoneNumber,
        channel,
        authenticationSID
    }) => {
        let body = {
            twoFactorAuthenticationPhoneNumber,
            channel,
            authenticationSID
        };
        const Break = {};
        fetch(process.env.REACT_APP_API_URL + '/multipurposeSendTwoFactorAuthenticationCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(body)
        })
            .then(async res => {
                if (res.status === 200) {
                    const json = await res.json();
                    let currentTime = moment(new Date());
                    // if (!_.isEmpty(phoneNumber)) {
                    //     setTwoFactorAuthenticationPhoneNumber(phoneNumber);
                    // }
                    setAuthenticationSID(json.authenticationSID);
                    setTwoFactorAuthenticationCodeInputDisabled(false);
                    setEnableSendTime(currentTime.add(json.timeUntilNextRetry, 's'));
                    disableSendButton(json.timeUntilNextRetry);
                    focusOnCodeInput();
                    onSnackbar(loc('authCodeSent', lang), 'success');
                } else if (res.status === 429) {
                    try {
                        const json = await res.json();
                        setAccountLocked(true);
                        setLockAccountUntil(json.lockAccountUntil);
                    } catch (err) {}
                    setInProgress(false);
                    onSnackbar(loc('tooManyAttempts', lang), 'error');
                    throw new Break();
                } else if (res.status === 400) {
                    let message;
                    try {
                        message = (await res.json()).message; // error message supplied by the server
                    } catch (err) {
                        message = 'Bad request.'; // error message to be generated locally
                    }
                    setInProgress(false);
                    onSnackbar(message, 'error');
                } else {
                    setInProgress(false);
                    onSnackbar(loc('somethingWentWrong', lang), 'error');
                }
            })
            .catch(Break => {})
            .catch(err => {
                console.error('POST /login', err);
                onSnackbar(loc('connectionErr', lang), 'error');
            });
    };

    const handlePreRegistrationVerify = async e => {
        let body = {
            twoFactorAuthenticationCode,
            twoFactorAuthenticationPhoneNumber: form.phone
        };
        setInProgress(true);
        fetch(process.env.REACT_APP_API_URL + '/multipurposeCheckTwoFactorAuthenticationCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(body)
        })
            .then(async res => {
                if (res.status === 200) {
                    setPreRegistration2FAComplete(true);
                } else if (res.status === 400) {
                    let message;
                    try {
                        message = (await res.json()).message; // error message supplied by the server
                    } catch (err) {
                        message = 'Bad request.'; // error message to be generated locally
                    }
                    onSnackbar(message, 'error');
                } else if (res.status === 401) {
                    onSnackbar(loc('invalidAuthCode', lang), 'error');
                } else if (res.status === 429) {
                    try {
                        const json = await res.json();
                        setAccountLocked(true);
                        setLockAccountUntil(json.lockAccountUntil);
                    } catch (err) {}
                    onSnackbar(loc('tooManyAttempts', lang), 'error');
                } else if (res.status === 409) {
                    onSnackbar(loc('errorVerifyingCreds', lang), 'error');
                    setTwoFactorAuthenticationPhoneNumber('');
                    setTwoFactorAuthenticationStep(false);
                    history.push('/login');
                } else if (res.status === 502) {
                    onSnackbar(loc('serverUnreachable', lang), 'error');
                } else {
                    onSnackbar(loc('serverError', lang), 'error');
                }
                setInProgress(false);
                setTwoFactorAuthenticationCode('');
            })
            .catch(err => {
                console.error('POST /checkTwoFactorAuthenticationCode', err);
                setInProgress(false);
                onSnackbar(loc('connectionErr', lang), 'error');
            });
    };

    const handleSendVerificationCode = async ({
        twoFactorAuthenticationPhoneNumber,
        channel,
        authenticationSID,
        oAuthUID,
        email
    }) => {
        const { http } = props;

        let body = {
            twoFactorAuthenticationPhoneNumber,
            channel,
            authenticationSID,
            oAuthUID,
            email
        };
        const Break = {};
        fetch(process.env.REACT_APP_API_URL + '/sendTwoFactorAuthenticationCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(body)
        })
            .then(async res => {
                if (res.status === 200) {
                    const json = await res.json();
                    let currentTime = moment(new Date());
                    if (!_.isEmpty(phoneNumber)) {
                        setTwoFactorAuthenticationPhoneNumber(phoneNumber);
                    }
                    setAuthenticationSID(json.authenticationSID);
                    setTwoFactorAuthenticationCodeInputDisabled(false);
                    setEnableSendTime(currentTime.add(json.timeUntilNextRetry, 's'));
                    disableSendButton(json.timeUntilNextRetry);
                    focusOnCodeInput();
                    onSnackbar(loc('authCodeSent', lang), 'success');
                } else if (res.status === 429) {
                    try {
                        const json = await res.json();
                        setAccountLocked(true);
                        setLockAccountUntil(json.lockAccountUntil);
                    } catch (err) {}
                    setInProgress(false);
                    onSnackbar(loc('tooManyAttempts', lang), 'error');
                    throw new Break();
                } else if (res.status === 400) {
                    let message;
                    try {
                        message = (await res.json()).message; // error message supplied by the server
                    } catch (err) {
                        message = 'Bad request.'; // error message to be generated locally
                    }
                    setInProgress(false);
                    onSnackbar(message, 'error');
                } else {
                    setInProgress(false);
                    onSnackbar(loc('somethingWentWrong', lang), 'error');
                }
            })
            .catch(Break => {})
            .catch(err => {
                console.error('POST /login', err);
                onSnackbar(loc('connectionErr', lang), 'error');
            });
    };

    const handleVerify = async e => {
        const { http, setOnLoginMessage, setOnLoginMessageVariant } = props;

        let deviceInfo = await getDeviceInformation();
        let body = {
            oAuthUID,
            email: form.email.toLowerCase().trim(),
            password: form.password,
            deviceInfo,
            timezone: _time.getTimezone(),
            twoFactorAuthenticationCode,
            twoFactorAuthenticationPhoneNumber
        };
        setInProgress(true);
        const Break = {};
        fetch(process.env.REACT_APP_API_URL + '/checkTwoFactorAuthenticationCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(body)
        })
            .then(async res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 400) {
                    let message;
                    try {
                        message = (await res.json()).message; // error message supplied by the server
                    } catch (err) {
                        message = 'Bad request.'; // error message to be generated locally
                    }
                    setTwoFactorAuthenticationCode('');
                    setInProgress(false);
                    onSnackbar(message, 'error');
                } else if (res.status === 401) {
                    setTwoFactorAuthenticationCode('');
                    setInProgress(false);
                    onSnackbar(loc('invalidAuthCode', lang), 'error');
                    throw new Break();
                } else if (res.status === 429) {
                    try {
                        const json = await res.json();
                        setAccountLocked(true);
                        setLockAccountUntil(json.lockAccountUntil);
                    } catch (err) {}
                    setTwoFactorAuthenticationCode('');
                    setInProgress(false);
                    onSnackbar(loc('tooManyAttempts', lang), 'error');
                    throw new Break();
                } else if (res.status === 409) {
                    setTwoFactorAuthenticationCode('');
                    setInProgress(false);
                    onSnackbar(loc('errorVerifyingCreds', lang), 'error');
                    setTwoFactorAuthenticationCode('');
                    setTwoFactorAuthenticationPhoneNumber('');
                    setTwoFactorAuthenticationStep(false);
                    history.push('/login');
                    throw new Break();
                } else if (res.status === 502) {
                    setTwoFactorAuthenticationCode('');
                    setInProgress(false);
                    onSnackbar(loc('serverUnreachable', lang), 'error');
                    throw new Break();
                } else {
                    setTwoFactorAuthenticationCode('');
                    setInProgress(false);
                    onSnackbar(loc('serverError', lang), 'error');
                    throw new Break();
                }
            })
            .then(data => {
                if (loginOnSuccess) {
                    // set home url and authorized
                    let homeURL;
                    switch (data.accountType) {
                        case 'Customer':
                            homeURL = '/customers/' + data._id;
                            if (!_.isEmpty(data.charities)) {
                                homeURL += '/' + data.charities + '/charity';
                            }
                            break;
                        case 'Collector Employee':
                            homeURL = `/operators/${data._id}/driver`;
                            break;
                        case 'Collector Administrator':
                            homeURL = `/operators/${data._id}/collector`;
                            break;
                        case 'System Administrator':
                            homeURL = '/operators/' + data._id;
                            break;
                        default:
                            throw new Error('Unexpected account type.');
                    }

                    // Redirect login to charity page if registered account is a charity
                    if (
                        [REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex) &&
                        homeURL !== '/login'
                    ) {
                        homeURL += '/charity';
                    }

                    Analytics.logFacebookEvent('Customer Login');

                    dispatch({
                        type: 'SET_AUTH_TRUE',
                        accountType: data.accountType,
                        multipleAccountAccessList: data.multipleAccountAccessList,
                        name: !_.isNil(data.name) ? data.name.first + ' ' + data.name.last : undefined,
                        _id: data._id,
                        collector_id: _.get(data, 'collector._id', undefined),
                        home: homeURL
                    });
                    //redirect to home url

                    history.push(homeURL);
                } else {
                    setAccountLinkSuccessful(true);
                }
            })
            .catch(Break => {})
            .catch(err => {
                console.error('POST /checkTwoFactorAuthenticationCode', err);
                setInProgress(false);
                onSnackbar(loc('connectionErr', lang), 'error');
            });
    };

    const handleNoSMSDialogNo = () => {
        setNoSMSDialogOpen(false);
        setPhoneNumberInputRequired(true);
        onSnackbar(loc('registration2fa12', lang), 'info');
    };

    const handleContactForm = state => () => {
        setContactFormOpen(state);
    };

    const handleContactSubmitSuccess = () => {
        setContactFormOpen(false);
        onSnackbar(loc('contactFormSuccess', lang));
    };

    const handleOrgDialogClose = async e => {
        setAskForCharityDialog(false);
        setPromptedForCharity(true);
        handleSubmit();
    };

    const handleSetDonationPreference = selectedCharity => {
        setPromptedForCharity(true);
        setAskForCharityDialog(false);
        handleSubmit(selectedCharity);
    };

    const handleAddressChange = () => {
        setSuggestedCoordinates(null);
        const updatedForm = { ...form, location: getEmptyLocation() };
        setForm(updatedForm);
        validateForm(['location'], updatedForm);
    };

    const toggleGPSOverride = () => {
        let locationUpdated = form.location;

        if (_.get(form.location, 'isCustomGPS', false) && !_.isNil(suggestedCoordinates)) {
            locationUpdated.lat = _.get(suggestedCoordinates, 'lat', '');
            locationUpdated.lng = _.get(suggestedCoordinates, 'lng', '');
        }

        locationUpdated.isCustomGPS = !_.get(form.location, 'isCustomGPS', false);

        const updatedForm = { ...form, location: locationUpdated };
        setForm(updatedForm);
        validateForm(['location'], updatedForm);
    };

    const handleCoordinateChange = e => {
        let path = e.target.name;
        let value = e.target.value;

        const locationUpdated = { ...form.location, [path]: value };

        const updatedForm = { ...form, location: locationUpdated };
        setForm(updatedForm);
        validateForm(['location'], updatedForm);
    };

    const handleSuggestionSelected = ({ suggestion, place }) => {
        let locationUpdated = createLocation(
            place,
            suggestion.description,
            place.geometry.location.lat(),
            place.geometry.location.lng(),
            _.get(_.find(place.address_components, ac => ac.types.includes('locality')), 'long_name', undefined),
            _.get(
                _.find(place.address_components, ac => ac.types.includes('administrative_area_level_1')),
                'long_name',
                undefined
            ),
            _.get(
                _.find(place.address_components, component => component.types.includes('postal_code')),
                'short_name',
                undefined
            )
        );

        setSuggestedCoordinates({ lat: locationUpdated.lat, lng: locationUpdated.lng });
        const updatedForm = { ...form, location: locationUpdated };
        setForm(updatedForm);
        validateForm(['location'], updatedForm);
    };

    const handleRemovePromo = promo => {
        setPromos(_.filter(promos, p => p.code !== promo.code));
    };

    const handleCharityPreferred = charity => {
        setCharity(charity);
        const updatedForm = { ...form, charityPreferred: _charity.getId(charity) };
        setForm(updatedForm);
        validateForm(['charityPreferred'], updatedForm);
    };

    const filterCharitiesByRegion = () => {
        let charitiesSameCity = featuredCharities;

        const userCity = getClosestRegion(ipAddressLocation, regions);
        charitiesSameCity = _.filter(featuredCharities, charity => {
            //if no city locations set show featured charity anyway
            if (_.isNil(charity.regionsFeaturedIn)) {
                return true;
            } else {
                return (
                    _.find(charity.regionsFeaturedIn, cityId => cityId === _.get(userCity, '_id', null)) !== undefined
                );
            }
        });

        return charitiesSameCity;
    };

    //for customers, sets subdivision to donate to
    const handleSubdivision = subdivision => {
        setSubdivision(subdivision);
    };

    const handleBottleDriveCharityChange = charity => () => {
        const updatedForm = { ...form, bottleDriveCharity: charity };
        setForm(updatedForm);
        validateForm(['bottleDriveCharity'], updatedForm);
    };

    const handleSetDonate = donate => {
        setBottleDriveDonate(donate);
        if (!donate) {
            const updatedForm = { ...form, bottleDriveCharity: null };
            setForm(updatedForm);
            validateForm(['bottleDriveCharity'], updatedForm);
        }
    };

    useEffect(() => {
        const inputPhoneNumber = phoneNumber.replace(/\D/g, '');
        if (inputPhoneNumber.length > 0 && inputPhoneNumber.length !== 10) {
            setPhoneNumberError(true);
        } else {
            setPhoneNumberError(false);
        }
    }, [phoneNumber]);

    useEffect(() => {
        if (!defaultCoordinatesLoading) {
            setIPAddressLocation(createLocation({}, '', defaultCoordinates.latitude, defaultCoordinates.longitude));
        }
    }, [defaultCoordinatesLoading]);

    useEffect(() => {
        let tabParam = !_.isNil(tabIndex) ? `tab=${convertIndexToTabParam(tabIndex)}` : '';
        let orgParam = _.isNil(charity) ? `` : `&org=${charity._id}`;
        let subdivisionParam = !_.isNil(subdivision) ? `&subdivision=${encodeURIComponent(subdivision)}` : '';
        history.push('/register?' + tabParam + orgParam + subdivisionParam);
        /*document.title = `${process.env.REACT_APP_BRAND_NAME} | Sign up ${
            !_.isNil(tabIndex) ? `| ${_.startCase(convertIndexToTabParam(tabIndex))}` : ''
        }`;*/
    }, [tabIndex]);

    const fetchCharities = async () => {
        const [resCharities, resRegions] = await Promise.all([
            http.getJSON('/allCharities', false, true),
            http.getJSON('/regions', false, true)
        ]);
        if (resCharities.ok && resRegions.ok) {
            let locationCopy = _.cloneDeep(ipAddressLocation);
            locationCopy.lat = resRegions.data.lat;
            locationCopy.lng = resRegions.data.lng;
            if (isMounted()) {
                setCharities(_.get(resCharities, 'data.charities', []));
                setRegions(_.get(resRegions, 'data.regions', []));
                setIPAddressLocation(locationCopy);
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            //document.title = `${process.env.REACT_APP_BRAND_NAME} | Sign up`;

            setServicesLoading(true);
            queryUrl(isMounted());
            const [resRegistrationConfig, resServices] = await Promise.all([
                http.getJSON('/registrationConfig', false, true),
                http.getJSON('/services', false, true)
            ]);
            fetchCharities();
            setServicesLoading(false);
            //const resRegions = await http.getJSON('/regions', false, true);

            //let res = await http.getJSON('/getFeaturedCharities', false, true);

            //let res = await http.getJSON('/allCharities', false, true);
            if (resRegistrationConfig.ok) {
                setQuestionnaire(_.get(resRegistrationConfig, 'data.questionnaire', null));
                setRegistrationConfig(_.get(resRegistrationConfig, 'data', null));
                setRequire2FABeforeRegistration(
                    _.get(resRegistrationConfig, 'data.require2FABeforeCompletion', false) &&
                        _.get(resRegistrationConfig, 'data.twoFactorAuthenticationEnabled', false)
                );
            }

            if (resServices.ok) {
                const services = _.get(resServices, 'data.services', []);
                setServices(services);
                const mobilePickupSubServices = _.get(
                    _.find(services, service => service.isMobilePickupService && !service.disabled),
                    'subServices',
                    []
                ).filter(
                    service =>
                        !service.disabled &&
                        !(
                            service.charityLocked &&
                            [REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex)
                        )
                );
                const updatedForm = {
                    ...form,
                    locationType: _.get(_.first(mobilePickupSubServices), 'pickupType', 'Residential')
                };
                setForm(updatedForm);
                validateForm('locationType', updatedForm);
            }

            if (deviceHelper.isNativeApp()) {
                App.addListener('appUrlOpen', data => {
                    if (data && data.url) {
                        let nativeUrl = data.url.replace(/^[a-zA-Z]{3,5}\:\/{2}[a-zA-Z0-9_.:-]+\//, '');
                        console.log('Native URL: ', nativeUrl);
                        history.push(`/${nativeUrl}`);
                        queryUrl(isMounted());
                    }
                });

                try {
                    const availableResult = await NativeBiometric.isAvailable();
                    setBiometricsAvailable(availableResult.isAvailable);
                } catch (err) {}
            }
            window.addEventListener('resize', handleResize);

            let deviceInfo = await getDeviceInformation();
            setIsInAppBrowser(deviceInfo.isInAppBrowser);
        };
        fetchData();
    }, [isMounted]);

    useEffect(() => {
        if (auth.isAuthenticated && !_.isEmpty(auth.home)) {
            history.push(auth.home); //redirect to home url when authenticated
        }
    }, [auth, history]);

    const formControlStyle = {
        marginTop: theme.spacing.unit
    };

    const textFieldStyle = {};

    const formSelector = (
        <FormSelector
            handleTabIndexChange={handleTabIndexChange}
            handleFAQ={handleFAQ}
            handleHowItWorks={handleHowItWorks}
            registrationTagline={_.get(registrationConfig, `tagline.${lang}`, null)}
            registrationOptions={_.get(registrationConfig, `options`, {})}
            theme={theme}
            isInAppBrowser={isInAppBrowser}
            helpCenterFunctions={helpCenterFunctions}
            handleHelpCenter={handleHelpCenter}
            availableLangs={availableLangs}
        />
    );

    const accountInformationForm = (
        <AccountInformationForm
            promoInputShown={promoInputShown}
            tabIndex={tabIndex}
            hasAnAccount={hasAnAccount}
            promptedHasAnAccount={promptedHasAnAccount}
            redirectedFromApp={redirectedFromApp}
            showAlternativeLogins={showAlternativeLogins}
            formControlStyle={formControlStyle}
            textFieldStyle={textFieldStyle}
            form={form}
            formErrors={formErrors}
            promos={promos}
            questionnaire={questionnaire}
            services={services}
            servicesLoading={servicesLoading}
            questionnaireFormIncomplete={questionnaireFormIncomplete}
            questionnaireReponses={questionnaireResponses}
            touchedInputs={touchedInputs}
            overrideTouched={overrideTouched}
            biometricsAvailable={biometricsAvailable}
            biometricsOptIn={biometricsOptIn}
            handleResponseSelected={handleResponseSelected}
            handleCustomReponseChanged={handleCustomReponseChanged}
            handleSetHasAnAccount={handleSetHasAnAccount}
            handleCustomAuth={handleCustomAuth}
            handleOAuth={handleOAuth}
            handleFormChange={handleFormChange}
            handleChangeLocationType={handleChangeLocationType}
            handleApplyPromo={promo => setPromos([...promos, promo])}
            handleRemovePromo={handleRemovePromo}
            handleCharityPreferred={handleCharityPreferred}
            handleShowPromoInput={() => setPromoInputShown(true)}
            toggleGPSOverride={toggleGPSOverride}
            handleCoordinateChange={handleCoordinateChange}
            handleAddressChange={handleAddressChange}
            handleSuggestionSelected={handleSuggestionSelected}
            handleToggleBiometricsOptIn={() => setBiometricsOptIn(!biometricsOptIn)}
            charityEnabled={props.charityEnabled}
            registrationConfig={registrationConfig}
            width={windowWidth}
        />
    );

    const existingAccountScreen = (
        <div
            style={{
                marginTop: theme.spacing.unit * 4,
                marginBottom: theme.spacing.unit * 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Typography variant="h6">{loc('registrationOrg5', lang)}</Typography>
            <Button
                data-cy="registration-existing-account-yes"
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                style={{
                    marginTop: theme.spacing.unit,
                    maxWidth: 250
                }}
                onClick={() => handleSetHasAnAccount(true)}
            >
                {loc('yes', lang)}
            </Button>
            <Button
                data-cy="registration-existing-account-no"
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                style={{
                    marginTop: theme.spacing.unit * 2,
                    maxWidth: 250
                }}
                onClick={() => handleSetHasAnAccount(false)}
            >
                {loc('no', lang)}
            </Button>
        </div>
    );

    const charityInformationForm = (
        <CharityInformationForm
            formControlStyle={formControlStyle}
            registrationConfig={registrationConfig}
            textFieldStyle={textFieldStyle}
            form={form}
            formErrors={formErrors}
            file={file}
            taxReceiptsIssued={taxReceiptsIssued}
            differentTeams={differentTeams}
            oAuthRegistration={oAuthRegistration}
            handleAddressChange={handleAddressChange}
            handleFormChange={handleFormChange}
            handleFormCloseDateChange={handleFormCloseDateChange}
            handleSuggestionSelected={handleSuggestionSelected}
            handleDrop={handleDrop}
            handleToggleTaxReceiptsIssued={handleToggleTaxReceiptsIssued}
            handleToggleDifferentTeams={handleToggleDifferentTeams}
            handleSubdivisionsChange={handleSubdivisionsChange}
            toggleGPSOverride={toggleGPSOverride}
            handleCoordinateChange={handleCoordinateChange}
        />
    );
    const bottleDriveForm = (
        <BottleDriveInformationForm
            formControlStyle={formControlStyle}
            textFieldStyle={textFieldStyle}
            handleBackToSelection={handleBackToSelection}
        />
    );

    let registrationButtonVisible =
        [REGISTRATION_TABS.PERSONAL, REGISTRATION_TABS.BUSINESS].includes(tabIndex) ||
        ([REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex) && activeStep === 2);
    let nextButtonVisible = [REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex) && activeStep === 1;
    let backButtonVisible =
        [REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex) &&
        (activeStep === 1 || activeStep === 2);

    const charitiesSameCity = filterCharitiesByRegion();
    //const charitiesSameCitySorted = charityTaskHelper.sortyCharitiesByNumberCompletedTasks(charitiesSameCity);

    const logo = require(process.env.REACT_APP_LOGO);

    const splashScreenBackgroundColor = isAUSRegion() ? '#454545' : theme.palette.primary.main;
    let angleAdjustment = windowHeight * 0.1;
    let splashScreenAdjustment = splashScreen ? windowHeight * 0.2 : 0;
    let mySVG = `<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 ${windowWidth} ${windowHeight}" class="css-conixt ekalwmn1"><path d="M0 ${windowHeight} L0 ${windowHeight /
        2 +
        angleAdjustment +
        splashScreenAdjustment} L${windowWidth} ${windowHeight / 2 -
        angleAdjustment +
        splashScreenAdjustment} L${windowWidth} ${windowHeight} Z" fill="#dde3e8" ></path></svg>`;
    let mySVG64 = window.btoa(mySVG);

    const isEXP = isEXPRegion();
    const isSmallScreen = windowWidth < 1000;
    return _.isNil(_.get(registrationConfig, `tagline.${lang}`, null)) ? (
        <LoadingScreen />
    ) : (
        <>
            <Helmet>
                <title>
                    {`${process.env.REACT_APP_BRAND_NAME} | ${loc('signUp', lang)} ${
                        !_.isNil(tabIndex) ? `| ${_.startCase(convertIndexToTabParam(tabIndex))}` : ''
                    }`}
                </title>
            </Helmet>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                    backgroundColor: splashScreenBackgroundColor,
                    backgroundImage: `url("${backgroundImage()}")`,
                    backgroundRepeat: isEXP && isSmallScreen ? 'no-repeat' : 'repeat',
                    backgroundSize: isEXP ? (isSmallScreen ? '100%' : '500px') : '310px 310px'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                        minWidth: '100%',
                        backgroundImage: "url('data:image/svg+xml;base64," + mySVG64 + "')",
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'bottom',
                        backgroundSize: '100%',
                        overflowY: 'scroll'
                    }}
                >
                    {/* <ContainerWithLogo brandLogoOverride={_.get(props, 'brandLogoOverride')}> */}
                    {/* <div
                        style={{
                            position: splashScreen ? 'absolute' : '',
                            top: splashScreen ? '30%' : '',
                            marginTop: splashScreen ? '' : theme.spacing.unit * 5
                        }}
                    > */}
                    <img
                        src={_.get(props, 'brandLogoOverride.url', logo)}
                        alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
                        style={{
                            margin: '0px auto',
                            marginTop: '30px', //theme.spacing.unit * 5,
                            height: process.env.REACT_APP_REGION_EXT === 'STD' ? 'auto' : 100,
                            maxWidth: '85%'
                        }}
                    />
                    {/* </div> */}
                    <div style={{ display: 'flex' }}>
                        <div
                            style={{
                                maxWidth: 480,
                                marginTop: '30px', //theme.spacing.unit * 3,
                                paddingTop: `calc(env(safe-area-inset-top)` //`calc(env(safe-area-inset-top) + ${theme.spacing.unit * 2}px)`
                            }}
                        >
                            {!splashScreen && !_.isNil(_.get(registrationConfig, `tagline.${lang}`, null)) && (
                                <Paper
                                    elevation={4}
                                    style={{
                                        backgroundColor: theme.palette.background.default,
                                        margin: theme.spacing.unit * 2,
                                        marginTop: 0,
                                        marginBottom: theme.spacing.unit * 4,
                                        padding: theme.spacing.unit,
                                        borderRadius: 15
                                    }}
                                >
                                    <Collapse
                                        in={
                                            !_.isNil(charity) &&
                                            [REGISTRATION_TABS.PERSONAL, REGISTRATION_TABS.BUSINESS].includes(tabIndex)
                                        }
                                    >
                                        <CharityInfo
                                            charity={charity}
                                            style={{ marginTop: -theme.spacing.unit * 3 }}
                                            subdivision={subdivision}
                                            onSubdivision={handleSubdivision}
                                        />
                                    </Collapse>

                                    <div>
                                        <Typography
                                            variant="h5"
                                            align="center"
                                            style={{ fontSize: '1.75rem', marginTop: theme.spacing.unit * 3 }}
                                        >
                                            {twoFactorAuthenticationStep
                                                ? loc('twoFactorAuthentication', lang)
                                                : _.isNil(tabIndex)
                                                ? loc('accountSignUp', lang)
                                                : loc(pageTitle[tabIndex], lang)}
                                        </Typography>

                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: theme.spacing.unit * 2
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexWrap: 'wrap',
                                                    width: '100%'
                                                }}
                                            >
                                                {_.isNil(tabIndex) ? (
                                                    formSelector
                                                ) : (
                                                    <React.Fragment>
                                                        <Collapse in={!twoFactorAuthenticationStep}>
                                                            {[
                                                                REGISTRATION_TABS.GROUP,
                                                                REGISTRATION_TABS.CHARITY
                                                            ].includes(tabIndex) &&
                                                                activeStep === 0 && (
                                                                    <Typography variant="body2" color="textSecondary">
                                                                        {tabIndex !== 0 ||
                                                                        _.isEmpty(
                                                                            _.get(
                                                                                registrationConfig,
                                                                                `tagline.${lang}`,
                                                                                null
                                                                            )
                                                                        ) ||
                                                                        _.isNil(
                                                                            _.get(
                                                                                registrationConfig,
                                                                                `tagline.${lang}`,
                                                                                null
                                                                            )
                                                                        )
                                                                            ? loc(descriptions[tabIndex], lang, {
                                                                                  organization: terms.ORGANIZATION_NAME.toLowerCase(),
                                                                                  brand:
                                                                                      process.env.REACT_APP_BRAND_NAME
                                                                              })
                                                                            : _.get(
                                                                                  registrationConfig,
                                                                                  `tagline.${lang}`,
                                                                                  null
                                                                              )}
                                                                    </Typography>
                                                                )}
                                                            {/* <Typography variant="body2" color="textSecondary">
                                                        {tabIndex !== 0 ||
                                                        _.isEmpty(registrationTagline) ||
                                                        _.isNil(registrationTagline)
                                                            ? loc(descriptions[tabIndex], lang, {
                                                                  organization: terms.ORGANIZATION_NAME.toLowerCase(),
                                                                  brand: process.env.REACT_APP_BRAND_NAME
                                                              })
                                                            : registrationTagline}
                                                    </Typography> */}
                                                            {/* <NewUserInfo
                                                        handleFAQ={handleFAQ}
                                                        handleHowItWorks={handleHowItWorks}
                                                    /> */}
                                                            {activeStep === 0 && !twoFactorAuthenticationStep && (
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    color="primary"
                                                                    style={{
                                                                        marginTop: theme.spacing.unit,
                                                                        textDecoration: 'underline',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={handleBackToSelection}
                                                                >
                                                                    {loc('registration11', lang)}
                                                                </Typography>
                                                            )}
                                                        </Collapse>
                                                        <div style={{ marginTop: theme.spacing.unit * 2 }}>
                                                            <Collapse
                                                                in={
                                                                    [
                                                                        REGISTRATION_TABS.PERSONAL,
                                                                        REGISTRATION_TABS.BUSINESS
                                                                    ].includes(tabIndex) && !twoFactorAuthenticationStep
                                                                }
                                                            >
                                                                {accountInformationForm}
                                                            </Collapse>
                                                            <Collapse
                                                                in={
                                                                    [
                                                                        REGISTRATION_TABS.GROUP,
                                                                        REGISTRATION_TABS.CHARITY
                                                                    ].includes(tabIndex) && !twoFactorAuthenticationStep
                                                                }
                                                            >
                                                                {activeStep === 0 && existingAccountScreen}
                                                                {activeStep === 1 && accountInformationForm}
                                                                {activeStep === 2 && charityInformationForm}
                                                            </Collapse>
                                                            <Collapse
                                                                in={
                                                                    tabIndex === REGISTRATION_TABS.DEPOT &&
                                                                    !twoFactorAuthenticationStep
                                                                }
                                                                style={{ textAlign: 'center' }}
                                                            >
                                                                <Button
                                                                    fullWidth
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleContactForm(true)}
                                                                >
                                                                    {loc('contactUs', lang)}
                                                                </Button>
                                                            </Collapse>
                                                            <Collapse
                                                                in={
                                                                    tabIndex === REGISTRATION_TABS.BOTTLE_DRIVE &&
                                                                    !twoFactorAuthenticationStep
                                                                }
                                                            >
                                                                {activeStep === 0 && bottleDriveForm}
                                                            </Collapse>
                                                        </div>
                                                        <Collapse
                                                            in={
                                                                require2FABeforeRegistration &&
                                                                !isIncompletePhoneNumber(form.phone)
                                                            }
                                                        >
                                                            {process.env.REACT_APP_ENV === 'TEST' && (
                                                                <Typography variant="body2" color="error">
                                                                    {loc('registration2fa3', lang)}
                                                                </Typography>
                                                            )}
                                                            <Paper
                                                                style={{
                                                                    boxShadow: 'none',
                                                                    margin: `${theme.spacing.unit}px 0px`,
                                                                    padding: theme.spacing.unit,
                                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                                    backgroundColor: preRegistration2FAComplete
                                                                        ? colors.green[500]
                                                                        : theme.palette.background.paper
                                                                }}
                                                            >
                                                                {preRegistration2FAComplete ? (
                                                                    <ListItem>
                                                                        <ListItemAvatar>
                                                                            <Icon
                                                                                style={{
                                                                                    color: 'white',
                                                                                    margin: theme.spacing.unit
                                                                                }}
                                                                            >
                                                                                check
                                                                            </Icon>
                                                                        </ListItemAvatar>
                                                                        <ListItemText>
                                                                            <Typography
                                                                                variant="body1"
                                                                                style={{ color: 'white' }}
                                                                            >
                                                                                {loc('phoneNumberVerified', lang)}
                                                                            </Typography>
                                                                        </ListItemText>
                                                                    </ListItem>
                                                                ) : (
                                                                    // <div
                                                                    //     style={{
                                                                    //         display: 'flex',
                                                                    //         justifyContent: 'center',
                                                                    //         verticalAlign: 'middle'
                                                                    //     }}
                                                                    // >
                                                                    //     <Typography
                                                                    //         variant="body2"
                                                                    //         style={{ color: 'white' }}
                                                                    //     >
                                                                    //         Phone Number Verified
                                                                    //     </Typography>
                                                                    // </div>
                                                                    <>
                                                                        <Typography
                                                                            variant="body1"
                                                                            style={{
                                                                                color: theme.palette.text.secondary
                                                                            }}
                                                                        >
                                                                            {loc('verifyPhone', lang)}
                                                                        </Typography>
                                                                        <div
                                                                            fullwidth
                                                                            style={{
                                                                                width: '100%',
                                                                                display: 'flex',
                                                                                flexDirection: 'column'
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <Typography
                                                                                    style={{
                                                                                        marginTop: theme.spacing.unit
                                                                                    }}
                                                                                    variant="body2"
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            color:
                                                                                                resendDisabled ||
                                                                                                isIncompletePhoneNumber(
                                                                                                    form.phone
                                                                                                )
                                                                                                    ? theme.palette.text
                                                                                                          .secondary
                                                                                                    : theme.palette
                                                                                                          .primary.main,
                                                                                            textDecoration:
                                                                                                resendDisabled ||
                                                                                                isIncompletePhoneNumber(
                                                                                                    form.phone
                                                                                                )
                                                                                                    ? ''
                                                                                                    : 'underline',
                                                                                            cursor:
                                                                                                resendDisabled ||
                                                                                                isIncompletePhoneNumber(
                                                                                                    form.phone,
                                                                                                    form
                                                                                                )
                                                                                                    ? 'default'
                                                                                                    : 'pointer'
                                                                                        }}
                                                                                        data-cy="2fa-resend-code-via-text"
                                                                                        onClick={
                                                                                            resendDisabled ||
                                                                                            isIncompletePhoneNumber(
                                                                                                form.phone
                                                                                            )
                                                                                                ? () => {}
                                                                                                : e => {
                                                                                                      e.preventDefault();
                                                                                                      handleSendVerificationCodeClick(
                                                                                                          'sms'
                                                                                                      );
                                                                                                  }
                                                                                        }
                                                                                    >
                                                                                        {`${loc(
                                                                                            require2FABeforeRegistration
                                                                                                ? 'sendViaText'
                                                                                                : 'resendViaText',
                                                                                            lang
                                                                                        )} >`}
                                                                                    </span>
                                                                                </Typography>
                                                                            </div>
                                                                            <div>
                                                                                <Typography
                                                                                    style={{
                                                                                        marginTop: theme.spacing.unit
                                                                                    }}
                                                                                    variant="body2"
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            color:
                                                                                                resendDisabled ||
                                                                                                isIncompletePhoneNumber(
                                                                                                    form.phone
                                                                                                )
                                                                                                    ? theme.palette.text
                                                                                                          .secondary
                                                                                                    : theme.palette
                                                                                                          .primary.main,
                                                                                            textDecoration:
                                                                                                resendDisabled ||
                                                                                                isIncompletePhoneNumber(
                                                                                                    form.phone
                                                                                                )
                                                                                                    ? ''
                                                                                                    : 'underline',
                                                                                            cursor:
                                                                                                resendDisabled ||
                                                                                                isIncompletePhoneNumber(
                                                                                                    form.phone
                                                                                                )
                                                                                                    ? 'default'
                                                                                                    : 'pointer'
                                                                                        }}
                                                                                        data-cy="2fa-resend-code-via-voice"
                                                                                        onClick={
                                                                                            resendDisabled ||
                                                                                            isIncompletePhoneNumber(
                                                                                                form.phone
                                                                                            )
                                                                                                ? () => {}
                                                                                                : e => {
                                                                                                      e.preventDefault();
                                                                                                      handleSendVerificationCodeClick(
                                                                                                          'call'
                                                                                                      );
                                                                                                  }
                                                                                        }
                                                                                    >
                                                                                        {`${loc(
                                                                                            require2FABeforeRegistration
                                                                                                ? 'sendViaVoice'
                                                                                                : 'resendViaVoice',
                                                                                            lang
                                                                                        )} >`}
                                                                                    </span>
                                                                                </Typography>
                                                                            </div>
                                                                            {resendDisabled && (
                                                                                <div
                                                                                    fullwidth
                                                                                    style={{ display: 'inline-flex' }}
                                                                                >
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="textSecondary"
                                                                                        style={{
                                                                                            float: 'right',
                                                                                            fontSize: '60%'
                                                                                        }}
                                                                                    >
                                                                                        {loc('registration2fa5', lang)}{' '}
                                                                                        <WaitToRetry
                                                                                            time={enableSendTime}
                                                                                        />
                                                                                    </Typography>
                                                                                </div>
                                                                            )}
                                                                            <div style={{ display: 'flex' }}>
                                                                                <FormControl
                                                                                    fullWidth
                                                                                    style={{
                                                                                        marginTop: theme.spacing.unit
                                                                                    }}
                                                                                >
                                                                                    <TextField
                                                                                        data-cy="login-2fa-code-input"
                                                                                        disabled={
                                                                                            twoFactorAuthenticationCodeInputDisabled
                                                                                        }
                                                                                        inputRef={preCodeInputRef}
                                                                                        variant="outlined"
                                                                                        name="twoFactorAuthenticationCode"
                                                                                        type="numeric"
                                                                                        label={loc(
                                                                                            'registration2fa6',
                                                                                            lang
                                                                                        )}
                                                                                        placeholder="e.g. 123456"
                                                                                        value={
                                                                                            twoFactorAuthenticationCode
                                                                                        }
                                                                                        InputProps={{
                                                                                            style: {
                                                                                                backgroundColor:
                                                                                                    theme.palette
                                                                                                        .background
                                                                                                        .paper
                                                                                            }
                                                                                        }}
                                                                                        onChange={
                                                                                            handleTwoFactorAuthenticationCodeChange
                                                                                        }
                                                                                    />
                                                                                </FormControl>
                                                                                <Button
                                                                                    data-cy="verify-btn"
                                                                                    disabled={
                                                                                        twoFactorAuthenticationCodeInputDisabled ||
                                                                                        _.isEmpty(
                                                                                            twoFactorAuthenticationCode
                                                                                        )
                                                                                    }
                                                                                    variant="contained"
                                                                                    color={
                                                                                        preRegistration2FAComplete
                                                                                            ? colors.green[500]
                                                                                            : 'primary'
                                                                                    }
                                                                                    style={{
                                                                                        display: 'inline',
                                                                                        // float: 'right',
                                                                                        margin: theme.spacing.unit / 2,
                                                                                        marginTop: theme.spacing.unit,
                                                                                        padding: theme.spacing.unit,
                                                                                        height: 56
                                                                                    }}
                                                                                    onClick={
                                                                                        handlePreRegistrationVerify
                                                                                    }
                                                                                >
                                                                                    {inProgress ? (
                                                                                        <CircularProgress
                                                                                            size={22}
                                                                                            thickness={4.8}
                                                                                            style={{
                                                                                                display: inProgress
                                                                                                    ? ''
                                                                                                    : 'none',
                                                                                                marginLeft: 4,
                                                                                                color: 'white',
                                                                                                verticalAlign: 'bottom'
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        loc('verify', lang)
                                                                                    )}
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Paper>
                                                        </Collapse>
                                                        {(nextButtonVisible || registrationButtonVisible) && (
                                                            <Collapse in={!twoFactorAuthenticationStep}>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        marginTop: theme.spacing.unit
                                                                    }}
                                                                >
                                                                    {(registrationButtonVisible ||
                                                                        backButtonVisible) && (
                                                                        <div fullwidth>
                                                                            <div style={{ display: 'flex' }}>
                                                                                {backButtonVisible && (
                                                                                    <Button
                                                                                        data-cy="registration-back-button"
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        style={{
                                                                                            height: promoInputShown
                                                                                                ? 56
                                                                                                : 40,
                                                                                            marginRight:
                                                                                                theme.spacing.unit
                                                                                        }}
                                                                                        onClick={handleBack}
                                                                                    >
                                                                                        {loc('back', lang)}
                                                                                    </Button>
                                                                                )}
                                                                                {backButtonVisible &&
                                                                                    [
                                                                                        REGISTRATION_TABS.PERSONAL,
                                                                                        REGISTRATION_TABS.BUSINESS
                                                                                    ].includes(tabIndex) &&
                                                                                    promosEnabled && (
                                                                                        <div
                                                                                            style={{
                                                                                                marginLeft:
                                                                                                    theme.spacing.unit
                                                                                            }}
                                                                                        >
                                                                                            <Collapse
                                                                                                in={!promoInputShown}
                                                                                            >
                                                                                                <Typography
                                                                                                    variant="body1"
                                                                                                    color="primary"
                                                                                                    style={{
                                                                                                        marginTop:
                                                                                                            theme
                                                                                                                .spacing
                                                                                                                .unit,
                                                                                                        cursor:
                                                                                                            'pointer'
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                        setPromoInputShown(
                                                                                                            true
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        data-cy={`${tabIndex}-registration-add-promo`}
                                                                                                    >
                                                                                                        {loc(
                                                                                                            'addPromoCode',
                                                                                                            lang
                                                                                                        )}
                                                                                                    </span>
                                                                                                </Typography>
                                                                                            </Collapse>
                                                                                            <Collapse
                                                                                                in={promoInputShown}
                                                                                            >
                                                                                                <FormControl
                                                                                                    fullWidth
                                                                                                    style={{
                                                                                                        marginTop:
                                                                                                            -theme
                                                                                                                .spacing
                                                                                                                .unit *
                                                                                                            2
                                                                                                    }}
                                                                                                >
                                                                                                    <PromoCodesInput
                                                                                                        customer={{}}
                                                                                                        promos={promos}
                                                                                                        onApplyPromo={promo =>
                                                                                                            setPromos([
                                                                                                                ...promos,
                                                                                                                promo
                                                                                                            ])
                                                                                                        }
                                                                                                        onRemovePromo={
                                                                                                            handleRemovePromo
                                                                                                        }
                                                                                                        charityPreferred={_.get(
                                                                                                            form,
                                                                                                            'charityPreferred'
                                                                                                        )}
                                                                                                        donate={
                                                                                                            !_.isNil(
                                                                                                                _.get(
                                                                                                                    form,
                                                                                                                    'charityPreferred'
                                                                                                                )
                                                                                                            )
                                                                                                        }
                                                                                                        onCharityPreferred={
                                                                                                            handleCharityPreferred
                                                                                                        }
                                                                                                    />
                                                                                                </FormControl>
                                                                                            </Collapse>
                                                                                        </div>
                                                                                    )}

                                                                                {registrationButtonVisible && (
                                                                                    <Button
                                                                                        data-cy="registration-register-button"
                                                                                        type="submit"
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        disabled={
                                                                                            containsErrors(
                                                                                                formErrors
                                                                                            ) ||
                                                                                            inProgress ||
                                                                                            servicesLoading ||
                                                                                            (questionnaireFormIncomplete &&
                                                                                                !hasAnAccount &&
                                                                                                !oAuthRegistration &&
                                                                                                overrideTouched) ||
                                                                                            (require2FABeforeRegistration &&
                                                                                                !preRegistration2FAComplete)
                                                                                        }
                                                                                        style={{
                                                                                            height: promoInputShown
                                                                                                ? 56
                                                                                                : 40,
                                                                                            minWidth: 120
                                                                                        }}
                                                                                        onClick={
                                                                                            require2FABeforeRegistration &&
                                                                                            !preRegistration2FAComplete
                                                                                                ? () => {}
                                                                                                : handleSubmitClicked
                                                                                        }
                                                                                    >
                                                                                        <span>
                                                                                            {loc('signUp', lang)}
                                                                                        </span>
                                                                                        {inProgress && (
                                                                                            <CircularProgress
                                                                                                size={22}
                                                                                                thickness={4.8}
                                                                                                style={{
                                                                                                    marginLeft:
                                                                                                        theme.spacing
                                                                                                            .unit,
                                                                                                    color: 'white',
                                                                                                    verticalAlign:
                                                                                                        'bottom'
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Button>
                                                                                )}
                                                                                {!backButtonVisible &&
                                                                                    [
                                                                                        REGISTRATION_TABS.PERSONAL,
                                                                                        REGISTRATION_TABS.BUSINESS
                                                                                    ].includes(tabIndex) &&
                                                                                    promosEnabled && (
                                                                                        <div
                                                                                            style={{
                                                                                                marginLeft:
                                                                                                    theme.spacing.unit
                                                                                            }}
                                                                                        >
                                                                                            <Collapse
                                                                                                in={!promoInputShown}
                                                                                            >
                                                                                                <Typography
                                                                                                    variant="body1"
                                                                                                    color="primary"
                                                                                                    style={{
                                                                                                        marginTop:
                                                                                                            theme
                                                                                                                .spacing
                                                                                                                .unit,
                                                                                                        cursor:
                                                                                                            'pointer'
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                        setPromoInputShown(
                                                                                                            true
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        data-cy={`${tabIndex}-registration-add-promo`}
                                                                                                    >
                                                                                                        {loc(
                                                                                                            'addPromoCode',
                                                                                                            lang
                                                                                                        )}
                                                                                                    </span>
                                                                                                </Typography>
                                                                                            </Collapse>
                                                                                            <Collapse
                                                                                                in={promoInputShown}
                                                                                            >
                                                                                                <FormControl
                                                                                                    fullWidth
                                                                                                    style={{
                                                                                                        marginTop:
                                                                                                            -theme
                                                                                                                .spacing
                                                                                                                .unit *
                                                                                                            2
                                                                                                    }}
                                                                                                >
                                                                                                    <PromoCodesInput
                                                                                                        customer={{}}
                                                                                                        promos={promos}
                                                                                                        onApplyPromo={promo =>
                                                                                                            setPromos([
                                                                                                                ...promos,
                                                                                                                promo
                                                                                                            ])
                                                                                                        }
                                                                                                        onRemovePromo={
                                                                                                            handleRemovePromo
                                                                                                        }
                                                                                                        charityPreferred={_.get(
                                                                                                            form,
                                                                                                            'charityPreferred'
                                                                                                        )}
                                                                                                        donate={
                                                                                                            !_.isNil(
                                                                                                                _.get(
                                                                                                                    form,
                                                                                                                    'charityPreferred'
                                                                                                                )
                                                                                                            )
                                                                                                        }
                                                                                                        onCharityPreferred={
                                                                                                            handleCharityPreferred
                                                                                                        }
                                                                                                    />
                                                                                                </FormControl>
                                                                                            </Collapse>
                                                                                        </div>
                                                                                    )}
                                                                            </div>

                                                                            {registrationButtonVisible && (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        color="textSecondary"
                                                                                        style={{
                                                                                            marginTop:
                                                                                                theme.spacing.unit,
                                                                                            fontSize: '60%'
                                                                                        }}
                                                                                    >
                                                                                        {loc('termAgreement1', lang)}{' '}
                                                                                        <ExternalLink
                                                                                            textInline={true}
                                                                                            text={loc(
                                                                                                'termsAndConditions',
                                                                                                lang
                                                                                            )}
                                                                                            url={`${
                                                                                                process.env
                                                                                                    .REACT_APP_ORIGIN_URL
                                                                                            }/terms`}
                                                                                        />{' '}
                                                                                        {loc('termAgreement2', lang)}{' '}
                                                                                        <ExternalLink
                                                                                            textInline={true}
                                                                                            text={loc(
                                                                                                'privacyNotice',
                                                                                                lang
                                                                                            )}
                                                                                            url={`${
                                                                                                process.env
                                                                                                    .REACT_APP_ORIGIN_URL
                                                                                            }/privacy`}
                                                                                        />
                                                                                        .
                                                                                    </Typography>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {nextButtonVisible && (
                                                                        <Button
                                                                            data-cy="registration-next-button"
                                                                            variant="contained"
                                                                            color="primary"
                                                                            disabled={
                                                                                containsErrors(formErrors) ||
                                                                                (questionnaireFormIncomplete &&
                                                                                    !hasAnAccount &&
                                                                                    overrideTouched)
                                                                            }
                                                                            style={{ height: 40 }}
                                                                            onClick={handleNext}
                                                                        >
                                                                            {loc('next', lang)}
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </Collapse>
                                                        )}
                                                        <Collapse in={twoFactorAuthenticationStep}>
                                                            {phoneNumberInputRequired ? (
                                                                <FormControl
                                                                    fullWidth
                                                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                                                >
                                                                    <Typography variant="body2" color="textSecondary">
                                                                        {loc('registration2fa1', lang)}
                                                                    </Typography>
                                                                    <TextField
                                                                        data-cy="login-phone-input"
                                                                        type="tel"
                                                                        variant="outlined"
                                                                        name="twoFactorAuthenticationPhoneNumber"
                                                                        label={loc('phoneNumber', lang)}
                                                                        value={phoneNumber}
                                                                        InputProps={{
                                                                            inputComponent: TextMaskPhoneNumber,
                                                                            style: {
                                                                                backgroundColor:
                                                                                    theme.palette.background.paper
                                                                            }
                                                                        }}
                                                                        onChange={handleUpdatePhoneNumber}
                                                                        error={phoneNumberError}
                                                                        helperText={
                                                                            phoneNumberError
                                                                                ? loc('validate1', lang)
                                                                                : ''
                                                                        }
                                                                        style={{ marginTop: theme.spacing.unit }}
                                                                    />
                                                                </FormControl>
                                                            ) : (
                                                                <Typography variant="body2" color="textSecondary">
                                                                    {loc('registration2fa13', lang)}{' '}
                                                                    {formatPhoneNumber(
                                                                        twoFactorAuthenticationPhoneNumber
                                                                    )}
                                                                    .
                                                                </Typography>
                                                            )}
                                                            {process.env.REACT_APP_ENV === 'TEST' && (
                                                                <Typography variant="body2" color="error">
                                                                    {loc('registration2fa3', lang)}
                                                                </Typography>
                                                            )}
                                                            {!phoneNumberInputRequired && (
                                                                <>
                                                                    <Typography
                                                                        align="right"
                                                                        onClick={() => setNoSMSDialogOpen(true)}
                                                                        style={{
                                                                            marginTop: theme.spacing.unit,
                                                                            color: theme.palette.primary.main,
                                                                            textDecoration: 'underline',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                    >
                                                                        {loc('registration2fa8', lang)}
                                                                    </Typography>
                                                                    <FormControl
                                                                        fullWidth
                                                                        style={{ marginTop: theme.spacing.unit }}
                                                                    >
                                                                        <TextField
                                                                            data-cy="login-2fa-code-input"
                                                                            disabled={
                                                                                twoFactorAuthenticationCodeInputDisabled
                                                                            }
                                                                            inputRef={codeInputRef}
                                                                            variant="outlined"
                                                                            name="twoFactorAuthenticationCode"
                                                                            type="numeric"
                                                                            label={loc('registration2fa6', lang)}
                                                                            placeholder="e.g. 123456"
                                                                            value={twoFactorAuthenticationCode}
                                                                            InputProps={{
                                                                                style: {
                                                                                    backgroundColor:
                                                                                        theme.palette.background.paper
                                                                                }
                                                                            }}
                                                                            onChange={
                                                                                handleTwoFactorAuthenticationCodeChange
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        color="textSecondary"
                                                                        style={{ fontSize: '80%' }}
                                                                    >
                                                                        {loc('registration2fa4', lang)}{' '}
                                                                        <a
                                                                            href={`mailto:${
                                                                                process.env
                                                                                    .REACT_APP_SERVICE_EMAIL_ADDRESS
                                                                            }`}
                                                                        >
                                                                            {
                                                                                process.env
                                                                                    .REACT_APP_SERVICE_EMAIL_ADDRESS
                                                                            }
                                                                        </a>
                                                                    </Typography>
                                                                </>
                                                            )}
                                                            <div
                                                                fullwidth
                                                                style={{
                                                                    display: 'inline-flex',
                                                                    float: 'right',
                                                                    justifyContent: 'right',
                                                                    marginTop: theme.spacing.unit / 2,
                                                                    flexWrap: 'wrap'
                                                                }}
                                                            >
                                                                {!phoneNumberInputRequired ? (
                                                                    <>
                                                                        <Button
                                                                            color="secondary"
                                                                            variant="contained"
                                                                            style={{
                                                                                display: 'inline',
                                                                                height: 40,
                                                                                margin: theme.spacing.unit / 2
                                                                            }}
                                                                            onClick={() =>
                                                                                setPhoneNumberInputRequired(true)
                                                                            }
                                                                        >
                                                                            {loc('registration2fa14', lang)}
                                                                        </Button>

                                                                        <Button
                                                                            data-cy="back-to-login-btn"
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            style={{
                                                                                display: 'inline',
                                                                                height: 40,
                                                                                margin: theme.spacing.unit / 2
                                                                            }}
                                                                            onClick={
                                                                                handleCancelTwoFactorAuthentication
                                                                            }
                                                                        >
                                                                            {loc('cancelLogin', lang)}
                                                                        </Button>
                                                                        <Button
                                                                            data-cy="verify-btn"
                                                                            disabled={
                                                                                twoFactorAuthenticationCodeInputDisabled ||
                                                                                _.isEmpty(twoFactorAuthenticationCode)
                                                                            }
                                                                            variant="contained"
                                                                            color="primary"
                                                                            style={{
                                                                                display: 'inline',
                                                                                // float: 'right',
                                                                                height: 40,
                                                                                margin: theme.spacing.unit / 2
                                                                            }}
                                                                            onClick={handleVerify}
                                                                        >
                                                                            {loc('verify', lang)}{' '}
                                                                            <CircularProgress
                                                                                size={22}
                                                                                thickness={4.8}
                                                                                style={{
                                                                                    display: inProgress ? '' : 'none',
                                                                                    marginLeft: 4,
                                                                                    color: 'white',
                                                                                    verticalAlign: 'bottom'
                                                                                }}
                                                                            />
                                                                        </Button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Button
                                                                            data-cy="cancel-change-phone-btn"
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            style={{
                                                                                display: 'inline',
                                                                                height: 40,
                                                                                marginLeft: theme.spacing.unit
                                                                            }}
                                                                            onClick={() => {
                                                                                setPhoneNumberInputRequired(false);
                                                                                setPhoneNumber('');
                                                                                setPhoneNumberError(true);
                                                                            }}
                                                                        >
                                                                            {loc('cancel', lang)}
                                                                        </Button>
                                                                        <Button
                                                                            data-cy="update-phone-btn"
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            disabled={phoneNumber.length !== 10}
                                                                            style={{
                                                                                display: 'inline',
                                                                                float: 'right',
                                                                                height: 40,
                                                                                marginLeft: theme.spacing.unit
                                                                            }}
                                                                            onClick={
                                                                                handleTwoFactorAuthenticationPhoneNumberChange
                                                                            }
                                                                        >
                                                                            {loc('update', lang)}{' '}
                                                                            <CircularProgress
                                                                                size={22}
                                                                                thickness={4.8}
                                                                                style={{
                                                                                    display: inProgress ? '' : 'none',
                                                                                    marginLeft: 4,
                                                                                    color: 'white',
                                                                                    verticalAlign: 'bottom'
                                                                                }}
                                                                            />
                                                                        </Button>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div
                                                                fullwidth
                                                                style={{
                                                                    width: '100%',
                                                                    display: 'flex',
                                                                    flexDirection: 'column'
                                                                }}
                                                            >
                                                                <div>
                                                                    {!phoneNumberInputRequired && (
                                                                        <Typography
                                                                            style={{
                                                                                marginTop: theme.spacing.unit
                                                                            }}
                                                                            variant="body2"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        resendDisabled ||
                                                                                        isIncompletePhoneNumber(
                                                                                            twoFactorAuthenticationPhoneNumber
                                                                                        )
                                                                                            ? theme.palette.text
                                                                                                  .secondary
                                                                                            : theme.palette.primary
                                                                                                  .main,
                                                                                    textDecoration:
                                                                                        resendDisabled ||
                                                                                        isIncompletePhoneNumber(
                                                                                            twoFactorAuthenticationPhoneNumber
                                                                                        )
                                                                                            ? ''
                                                                                            : 'underline',
                                                                                    cursor:
                                                                                        resendDisabled ||
                                                                                        isIncompletePhoneNumber(
                                                                                            twoFactorAuthenticationPhoneNumber
                                                                                        )
                                                                                            ? 'default'
                                                                                            : 'pointer'
                                                                                }}
                                                                                data-cy="2fa-resend-code-via-text"
                                                                                onClick={
                                                                                    resendDisabled ||
                                                                                    isIncompletePhoneNumber(
                                                                                        twoFactorAuthenticationPhoneNumber
                                                                                    )
                                                                                        ? () => {}
                                                                                        : e => {
                                                                                              e.preventDefault();
                                                                                              handleSendVerificationCodeClick(
                                                                                                  'sms'
                                                                                              );
                                                                                          }
                                                                                }
                                                                            >
                                                                                {`${loc('resendViaText', lang)} >`}
                                                                            </span>
                                                                        </Typography>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    {!phoneNumberInputRequired && (
                                                                        <Typography
                                                                            style={{
                                                                                marginTop: theme.spacing.unit
                                                                            }}
                                                                            variant="body2"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        resendDisabled ||
                                                                                        isIncompletePhoneNumber(
                                                                                            twoFactorAuthenticationPhoneNumber
                                                                                        )
                                                                                            ? theme.palette.text
                                                                                                  .secondary
                                                                                            : theme.palette.primary
                                                                                                  .main,
                                                                                    textDecoration:
                                                                                        resendDisabled ||
                                                                                        isIncompletePhoneNumber(
                                                                                            twoFactorAuthenticationPhoneNumber
                                                                                        )
                                                                                            ? ''
                                                                                            : 'underline',
                                                                                    cursor:
                                                                                        resendDisabled ||
                                                                                        isIncompletePhoneNumber(
                                                                                            twoFactorAuthenticationPhoneNumber
                                                                                        )
                                                                                            ? 'default'
                                                                                            : 'pointer'
                                                                                }}
                                                                                data-cy="2fa-resend-code-via-voice"
                                                                                onClick={
                                                                                    resendDisabled ||
                                                                                    isIncompletePhoneNumber(
                                                                                        twoFactorAuthenticationPhoneNumber
                                                                                    )
                                                                                        ? () => {}
                                                                                        : e => {
                                                                                              e.preventDefault();
                                                                                              handleSendVerificationCodeClick(
                                                                                                  'call'
                                                                                              );
                                                                                          }
                                                                                }
                                                                            >
                                                                                {`${loc('resendViaVoice', lang)} >`}
                                                                            </span>
                                                                        </Typography>
                                                                    )}
                                                                </div>
                                                                {resendDisabled && (
                                                                    <div fullwidth style={{ display: 'inline-flex' }}>
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="textSecondary"
                                                                            style={{
                                                                                float: 'right',
                                                                                fontSize: '60%'
                                                                            }}
                                                                        >
                                                                            {loc('registration2fa5', lang)}{' '}
                                                                            <WaitToRetry time={enableSendTime} />
                                                                        </Typography>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Collapse>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                        {!_.isNil(tabIndex) && availableLangs.length > 1 && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginTop: theme.spacing.unit,
                                                    marginBottom: theme.spacing.unit * 3
                                                }}
                                                data-cy="test-language-div"
                                            >
                                                <Icon color="inherit" style={{ marginRight: theme.spacing.unit }}>
                                                    language
                                                </Icon>
                                                <Select
                                                    value={lang}
                                                    onChange={e => setLang(e.target.value)}
                                                    data-cy="language-select"
                                                >
                                                    {availableLangs.map(availableLang => {
                                                        return (
                                                            <MenuItem
                                                                value={availableLang}
                                                                key={availableLang}
                                                                data-cy={`language-select-${availableLang}`}
                                                            >
                                                                {
                                                                    {
                                                                        en: 'English (CAN)',
                                                                        fr: 'Français (CAN)'
                                                                    }[availableLang]
                                                                }
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        )}
                                        {!_.isNil(tabIndex) && (
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                align="center"
                                                style={{
                                                    fontSize: '0.875rem',
                                                    marginTop: theme.spacing.unit * 3
                                                }}
                                            >
                                                {loc('alreadyHaveAccount', lang)}{' '}
                                                <Link
                                                    data-cy="sign-in-link"
                                                    to="/login"
                                                    style={{
                                                        color: theme.palette.primary.main
                                                    }}
                                                >
                                                    {loc('signIn', lang)}
                                                </Link>
                                            </Typography>
                                        )}
                                        {!_.isNil(tabIndex) && Object.values(helpCenterFunctions).includes(true) && (
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                align="center"
                                                style={{
                                                    fontSize: '0.875rem',
                                                    marginTop: theme.spacing.unit * 2,
                                                    marginBottom: theme.spacing.unit * 2
                                                }}
                                            >
                                                {loc('login3a', lang)}{' '}
                                                <FakeLink
                                                    theme={theme}
                                                    text={loc('login3b', lang)}
                                                    onClickEvent={handleHelpCenter(true)}
                                                    color={theme.palette.primary.main}
                                                    data-cy="registration-help-center"
                                                />
                                            </Typography>
                                        )}
                                    </div>
                                </Paper>
                            )}

                            {/* {!_.isNil(_.get(registrationConfig, `tagline.${lang}`, null)) ? (
                                <React.Fragment>
                                    {Object.values(helpCenterFunctions).includes(true) && (
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            align="center"
                                            style={{ fontSize: '1rem' }}
                                        >
                                            {loc('login3a', lang)}{' '}
                                            <FakeLink
                                                theme={theme}
                                                text={loc('login3b', lang)}
                                                onClickEvent={handleHelpCenter(true)}
                                                color={theme.palette.text.secondary}
                                                data-cy="registration-help-center"
                                            />
                                        </Typography>
                                    )}
                                </React.Fragment>
                            ) : null} */}
                            <Dialog open={helpCenterOpen} onClose={handleHelpCenter(false)} maxWidth="md">
                                <DialogTitle>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography variant="h5">{loc('login3b', lang)}</Typography>
                                    </div>
                                </DialogTitle>
                                <DialogContent>
                                    {helpCenterFunctions.showHowItWorks && (
                                        <RoundSquareButton
                                            button={{
                                                onClick: () => {
                                                    setHelpCenterOpen(false);
                                                    setHowItWorksOpen(true);
                                                },
                                                color: theme.palette.primary.main,
                                                text: loc('howItWorks', lang),
                                                'data-cy': 'registration-help-center-how-it-works'
                                            }}
                                            variant="contained"
                                            icon={'settings'}
                                            theme={theme}
                                            width={125}
                                            style={{ margin: theme.spacing.unit }}
                                        />
                                    )}
                                    {helpCenterFunctions.showFaq && (
                                        <RoundSquareButton
                                            button={{
                                                onClick: () => {
                                                    setHelpCenterOpen(false);
                                                    setFaqOpen(true);
                                                },
                                                color: theme.palette.secondary.main,
                                                text: 'FAQ',
                                                'data-cy': 'registration-help-center-faq'
                                            }}
                                            variant="contained"
                                            icon={'question_answer'}
                                            theme={theme}
                                            width={125}
                                            style={{ margin: theme.spacing.unit }}
                                        />
                                    )}
                                    {helpCenterFunctions.showContactUs && (
                                        <RoundSquareButton
                                            button={{
                                                onClick: () => {
                                                    setHelpCenterOpen(false);
                                                    setContactFormOpen(true);
                                                },
                                                color: theme.palette.text.disabled,
                                                text: loc('contactUs', lang),
                                                'data-cy': 'registration-help-center-contact-us'
                                            }}
                                            variant="contained"
                                            icon={'chat'}
                                            theme={theme}
                                            width={125}
                                            style={{ margin: theme.spacing.unit }}
                                        />
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        color="default"
                                        data-cy="bulk-counter-cancel"
                                        onClick={handleHelpCenter(false)}
                                        style={{}}
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <React.Fragment>
                                {helpCenterFunctions.showHowItWorks && (
                                    <HowItWorks
                                        open={howItWorksOpen}
                                        tabIndex={tabIndex}
                                        onFAQ={handleFAQ}
                                        onClose={handleHowItWorks(false)}
                                        useFallbackData={useFallbackData}
                                    />
                                )}
                                <FAQ
                                    open={faqOpen}
                                    tabIndex={tabIndex}
                                    onClose={handleFAQ(false)}
                                    useFallbackData={useFallbackData}
                                    http={http}
                                />
                                <ContactForm
                                    specification={
                                        tabIndex === REGISTRATION_TABS.DEPOT ? 'BOTTLE_DEPOT' : 'HELP_CENTER'
                                    }
                                    http={http}
                                    open={contactFormOpen}
                                    onSubmitSuccess={handleContactSubmitSuccess}
                                    onClose={handleContactForm(false)}
                                />
                                {!_.isNil(tabIndex) && (
                                    /* Moved the tabIndex Nil check down here from React.Fragment just in case  
                                    it is in some way important for the components below. */
                                    <>
                                        <OAuthDialog
                                            open={showAuthDialog}
                                            oAuthUser={oAuthUser}
                                            http={http}
                                            promoToApply={_.first(promos)}
                                            onClose={handleCancelOAuthRegistration}
                                            onNext={
                                                [REGISTRATION_TABS.PERSONAL, REGISTRATION_TABS.BUSINESS].includes(
                                                    tabIndex
                                                )
                                                    ? onRegisterOAuthCustomer
                                                    : handleNext
                                            }
                                            require2FABeforeRegistration={require2FABeforeRegistration}
                                            onSnackbar={onSnackbar}
                                            charityPreferred={_.get(form, 'charityPreferred')}
                                            onCharityPreferred={handleCharityPreferred}
                                        />

                                        <FeaturedCharitySelectDialog
                                            inProgress={inProgress}
                                            open={askForCharityDialog}
                                            featuredCharitiesSameRegion={charitiesSameCity}
                                            onClose={handleOrgDialogClose}
                                            handleSetDonationPreference={handleSetDonationPreference}
                                        />
                                    </>
                                )}
                            </React.Fragment>
                        </div>
                    </div>
                    {splashScreen && (
                        <div
                            fullwidth
                            style={{
                                position: 'absolute',
                                bottom: '10%'
                            }}
                        >
                            <Button
                                data-cy="login-btn"
                                variant="contained"
                                color="primary"
                                style={{
                                    display: 'inline',
                                    float: 'center',
                                    height: 50,
                                    textTransform: 'none',
                                    margin: theme.spacing.unit
                                }}
                                fullWidth
                                onClick={() => history.push('/login')}
                            >
                                {loc('logIn', lang)}
                            </Button>

                            <Button
                                data-cy="signup-btn"
                                variant="outlined"
                                color="primary"
                                style={{
                                    display: 'inline',
                                    float: 'center',
                                    height: 50,
                                    textTransform: 'none',
                                    margin: theme.spacing.unit
                                }}
                                fullWidth
                                onClick={() => setSplashScreen(false)}
                            >
                                {loc('signUp', lang)}
                            </Button>
                        </div>
                    )}
                    <Dialog open={accountLinkSuccessful} maxWidth="sm" fullWidth>
                        <DialogTitle>{loc('accountLink1', lang)}</DialogTitle>
                        <DialogContent>
                            <Typography>
                                {loc('accountLink2', lang, {
                                    accountType: ([REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(
                                        tabIndex
                                    )
                                        ? `${lang === 'en' ? 'an ' : 'une '}${loc('organizationName', lang, {
                                              organizationName: terms.ORGANIZATION_NAME
                                          })}`
                                        : `${lang === 'en' ? 'a ' : 'un '}${loc('bottleDrive', lang)}`
                                    ).toLowerCase()
                                })}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                data-cy="charity-select-dalog-yes-button"
                                color="primary"
                                onClick={() => history.push('/login')}
                            >
                                {loc('accountLink3', lang)}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <NoSMSDialog
                        open={noSMSDialogOpen}
                        phone={twoFactorAuthenticationPhoneNumber}
                        email={form.email}
                        firstName={form.firstName}
                        lastName={form.lastName}
                        onClose={() => setNoSMSDialogOpen(false)}
                        onNoClicked={handleNoSMSDialogNo}
                    />
                </div>
            </div>
            {/* )} */}
            {/* </ContainerWithLogo> */}
        </>
    );
}

export default withWidth()(withTheme()(withRouter(Registration)));

function parseTabIntoIndex(tab) {
    switch (tab) {
        case 'customer':
        case '0':
            return REGISTRATION_TABS.PERSONAL;
        case 'organization':
        case 'organisation':
        case '1':
            return REGISTRATION_TABS.CHARITY;
        case 'depot':
        case '2':
            return REGISTRATION_TABS.DEPOT;
        case 'bottleDrive':
            return REGISTRATION_TABS.BOTTLE_DRIVE;
        case 'business':
        case '4':
            return REGISTRATION_TABS.BUSINESS;
        case 'group':
        case '5':
            return REGISTRATION_TABS.GROUP;
        default:
            return REGISTRATION_TABS.PERSONAL;
    }
}

function convertIndexToTabParam(index) {
    switch (index) {
        case REGISTRATION_TABS.PERSONAL:
            return 'customer';
        case REGISTRATION_TABS.CHARITY:
            return 'organization';
        case REGISTRATION_TABS.DEPOT:
            return 'depot';
        case REGISTRATION_TABS.BOTTLE_DRIVE:
            return 'bottleDrive';
        case REGISTRATION_TABS.BUSINESS:
            return 'business';
        case REGISTRATION_TABS.GROUP:
            return 'group';
        default:
            return 'customer';
    }
}

function getValidatedKeys(
    tabIndex,
    hasAnAccount,
    taxReceiptsIssued,
    oAuthRegistration,
    isCommercial = false,
    registrationConfig
) {
    let keys;
    if (oAuthRegistration && [REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex)) {
        keys = keysCharity;
    } else if (oAuthRegistration && tabIndex === REGISTRATION_TABS.BOTTLE_DRIVE) {
        keys = keysBottleDrive;
    } else {
        keys = hasAnAccount
            ? _.without(keysValidated[tabIndex], ...keysUser).concat(keysExistingUser)
            : keysValidated[tabIndex];
    }

    if (!taxReceiptsIssued) {
        keys = _.without(keys, 'charityRegistrationNumber');
    }
    if (isCommercial) {
        keys = keys.concat(keysCommercial);
    }
    if (!_.get(registrationConfig, 'showPOBoxInput', false)) {
        keys = _.without(keys, 'POBox');
    }
    if (!_.get(registrationConfig, 'showRepeatPassword', false)) {
        keys = _.without(keys, 'repeatPassword');
    }
    if (!_.get(registrationConfig, 'showSecurityQuestions', false)) {
        keys = _.without(keys, 'securityQuestion');
        keys = _.without(keys, 'securityAnswer');
    }
    if (!_.get(registrationConfig, 'showBusinessPhone', false)) {
        keys = _.without(keys, 'businessPhone');
    }
    if (!_.get(registrationConfig, 'showBusinessNumber', false)) {
        keys = _.without(keys, 'businessNumber');
    }
    if (!_.get(registrationConfig, 'showSchemeID', false)) {
        keys = _.without(keys, 'schemeID');
        keys = _.without(keys, 'charitySchemeID');
    }

    return keys;
}

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
}

function WaitToRetry({ time }) {
    const [timeElapsed, setTimeElapsed] = useState(getTimeDifferenceFromNow(time));

    useEffect(() => {
        setInterval(() => {
            setTimeElapsed(getTimeDifferenceFromNow(time));
        }, 1000);
    });
    return <>{timeElapsed}</>;
}

function getTimeDifferenceFromNow(time) {
    return formatsSecondsToTime(
        moment(time)
            .diff(moment(new Date()), 'seconds')
            .toString()
    );
}
function isIncompletePhoneNumber(phoneNumber) {
    return phoneNumber.toString().length < 10;
}
